/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { dataImportReducerConstant } from '../../reducerConstant';
import { stringConstant } from '../../../constant';

const initialState = {
   dataSaveCompleted: null,
   dataSummaryOperationalArea: null,
   dataSummaryVendor: null,
   delimiterList: null,
   excelData: null,
   excelTemplate: null,
   featureTypes: null,
   fieldMappingErrorValidation: null,
   fieldMappingErrorValidationValue: null,
   fileName: stringConstant.EMPTY_STRING,
   formData: null,
   getAllUnitList: null,
   iliSummaryDashboardData: null,
   isChunksAdded: null,
   isOperationalAreaLoading: null,
   isSummaryLoading: null,
   isTemplateAdded: null,
   isVendorLoading: null,
   isVendorOperationalAdded: null,
   jointLength: false,
   lastJointLength: false,
   lazyLoadData: [],
   loader: false,
   masterList: null,
   matchingDetails: null,
   matchingDetailUpdate: false,
   operationalList: null,
   previousVersionQCData: null,
   qcDashboardData: null,
   qcInput: null,
   qcResponse: null,
   quantityList: null,
   revertCompleted: null,
   redundantProcessParams: null,
   idrMismatchSheets: [],
   savedInspectionGuid: null,
   selectedIliSummarySheetData: null,
   selectedOperationalArea: null,
   selectedVendor: null,
   isShowOverlayLoader: false,
   showOverlayLoaderMessage: stringConstant.EMPTY_STRING,
   summaryList: null,
   templateResult: null,
   unitList: null,
   vendorsList: null,
   versionList: null,
   toolTypeData: null,
   isVersionLoading: null,
   isValidationDialogVisible: false,
   validationBodyContent: null,
   validationExcelColumn: null,
   resultData: null,
   dataEntryValues: null,
   isForcedUpdateFieldMapping: true,
   clearExcelData: false,
   disableQCButtons: false
};

const dataImportManageReducer = (state = initialState, action = {}) => {
   switch (action.type) {
      case dataImportReducerConstant.VENDORS_LIST:
         return {
            ...state,
            vendorsList: action.value.data,
         };
      case dataImportReducerConstant.OPERATIONAL_AREA_LIST:
         return {
            ...state,
            operationalList: action.value.data,
         };
      case dataImportReducerConstant.UNIT_LIST:
         return {
            ...state,
            unitList: action.value.data,
         };
      case dataImportReducerConstant.VERSION_LIST:
         return {
            ...state,
            versionList: action.value.data,
         };
      case dataImportReducerConstant.ILI_SUMMARY_LIST:
         return {
            ...state,
            summaryList: action.value.data,
         };
      case dataImportReducerConstant.MASTER_LIST:
         return {
            ...state,
            masterList: action.value.data,
         };
      case dataImportReducerConstant.ALL_UNITS:
         return {
            ...state,
            getAllUnitList: action.value.data,
         };
      case dataImportReducerConstant.FETCH_EXCEL_DATA_SUCCESS:
         return {
            ...state,
            excelData: action.value,
            loader: false,
         };
      case dataImportReducerConstant.FETCH_EXCEL_DATA_FAILURE:
         return {
            ...state,
            loader: false,
         };
      case dataImportReducerConstant.DATA_IMPORT_LOADER:
         return {
            ...state,
            loader: true,
         };
      case dataImportReducerConstant.TEMPLATE_ADD_CLEAR:
         return {
            ...state,
            isTemplateAdded: null,
         };
      case dataImportReducerConstant.IS_TEMPLATE_SUCCESS:
         return {
            ...state,
            isTemplateAdded: true,
            fieldMappingErrorValidation: false,
            fieldMappingErrorValidationValue: null
         };
      case dataImportReducerConstant.FETCH_EXCEL_DATA_LAZY_LOAD_SUCCESS:
         return {
            ...state,
            lazyLoadData: action.value,
         };
      case dataImportReducerConstant.CLEAR_EXCEL_LAZY_LOAD:
         return {
            ...state,
            lazyLoadData: []
         };
      case dataImportReducerConstant.CHUNKS_ADD_CLEAR:
         return {
            ...state,
            isChunksAdded: null,
         };
      case dataImportReducerConstant.IS_CHUNKS_SUCCESS:
         return {
            ...state,
            isChunksAdded: true,
         };
      case dataImportReducerConstant.DATA_SAVE_LOADER:
         return {
            ...state,
            isShowOverlayLoader: action.value.val,
            showOverlayLoaderMessage: action.value.data
         };
      case dataImportReducerConstant.SELECTED_VENDOR_UPDATE:
         return {
            ...state,
            selectedVendor: action.value
         };
      case dataImportReducerConstant.CLEAR_VENDOR_OPAREA:
         return {
            ...state,
            dataSummaryOperationalArea: null,
            dataSummaryVendor: null,
            selectedVendor: null,
            selectedOperationalArea: null,
            excelData: null
         };
      case dataImportReducerConstant.SELECTED_OPERATIONAL_AREA_UPDATE:
         return {
            ...state,
            selectedOperationalArea: action.value
         };
      case dataImportReducerConstant.IS_VENDOR_OPERATIONAL_SUCCESS:
         return {
            ...state,
            isVendorOperationalAdded: true,
            dataSummaryVendor: action.value.iliToolVendorClGuid,
            dataSummaryOperationalArea: action.value.iliToolOperationalAreaClGuid
         };
      case dataImportReducerConstant.CLEAR_DATASUMMARY_VENDOR_OP_AREA:
         return {
            ...state,
            dataSummaryVendor: null,
            dataSummaryOperationalArea: null
         };
      case dataImportReducerConstant.IS_VENDOR_OPERATIONAL_CLEAR:
         return {
            ...state,
            isVendorOperationalAdded: null,
         };
      case dataImportReducerConstant.ERROR_VALIDATION_FIELDMAPPING:
         return {
            ...state,
            fieldMappingErrorValidation: true,
            fieldMappingErrorValidationValue: action.value
         };
      case dataImportReducerConstant.CLEAR_ERROR_VALIDATION_FIELDMAPPING:
         return {
            ...state,
            fieldMappingErrorValidation: null,
            fieldMappingErrorValidationValue: null,
            isShowOverlayLoader: false,
            showOverlayLoaderMessage: stringConstant.EMPTY_STRING,
            savedInspectionGuid: null,
            toolTypeData: null
         };
      case dataImportReducerConstant.UPDATE_EXCEL_TEMPLATE:
         return {
            ...state,
            excelTemplate: action.value
         };
      case dataImportReducerConstant.SET_QC_DASHBOARD_DATA:
         return {
            ...state,
            qcDashboardData: action.value.resultData,
            savedInspectionGuid: action.value.savedInspectionGuid,
            formData: action.value.formData,
            qcInput: action.value.qcInput,
            templateResult: action.value.templateResult,
            jointLength: action.value.jointLength,
            lastJointLength: action.value.lastJointLength
         };
      case dataImportReducerConstant.FETCH_UPDATED_MATCHING_DETAILS:
         return {
            ...state,
            matchingDetails: action.value,
            matchingDetailUpdate: true
         };
      case dataImportReducerConstant.CLEAR_MATCHING_DETAIL_UPDATE:
         return {
            ...state,
            matchingDetailUpdate: false
         };
      case dataImportReducerConstant.SAVE_QC_RESPONSE:
         return {
            ...state,
            qcResponse: action.value,
            dataSaveCompleted: true,
            excelData: null
         };
      case dataImportReducerConstant.CLEAR_QCDASHBOARD_DATA:
         return {
            ...state,
            qcDashboardData: null,
            dataSummaryOperationalArea: null,
            dataSummaryVendor: null,
            matchingDetails: null,
            jointLength: false,
            lastJointLength: false,
            formData: null,
            fileName: stringConstant.EMPTY_STRING,
            toolTypeData: null,
            excelData: null
         };
      case dataImportReducerConstant.RESET_QC_DATA:
         return {
            ...state,
            qcDashboardData: null,
         };
      case dataImportReducerConstant.REVERT_SUCCESS_REDIRECT:
         return {
            ...state,
            revertCompleted: action.value
         };
      case dataImportReducerConstant.SET_PREVIOUS_VERSION_QC_DATA:
         return {
            ...state,
            previousVersionQCData: action.value
         };
      case dataImportReducerConstant.QUANTITY_LIST:
         return {
            ...state,
            quantityList: action.value.data,
            isShowOverlayLoader: false,
            showOverlayLoaderMessage: null
         };
      case dataImportReducerConstant.CLEAR_DATA_SAVE_COMPLETED:
         return {
            ...state,
            dataSaveCompleted: null
         };
      case dataImportReducerConstant.FETCH_ILI_SUMMARY_DASHBOARD:
         return {
            ...state,
            iliSummaryDashboardData: action.value
         };
      case dataImportReducerConstant.SELECTED_ILISUMMARY_SHEETDATA:
         return {
            ...state,
            selectedIliSummarySheetData: action.value
         };
      case dataImportReducerConstant.SELECTED_ILISUMMARY_SHEETDATA:
         return {
            ...state,
            selectedIliSummarySheetData: action.value
         };
      case dataImportReducerConstant.FETCH_FEATURE_TYPE:
         return {
            ...state,
            featureTypes: action.value.data
         };
      case dataImportReducerConstant.SET_FILE_NAME:
         return {
            ...state,
            fileName: action.value,
            excelData: null
         };
      case dataImportReducerConstant.SET_SUMMARY_LOADER:
         return {
            ...state,
            isSummaryLoading: true
         };
      case dataImportReducerConstant.REMOVE_SUMMARY_LOADER:
         return {
            ...state,
            isSummaryLoading: false
         };
      case dataImportReducerConstant.SET_VENDOR_LOADER:
         return {
            ...state,
            isVendorLoading: true
         };
      case dataImportReducerConstant.REMOVE_VENDOR_LOADER:
         return {
            ...state,
            isVendorLoading: false
         };
      case dataImportReducerConstant.SET_OPERATIONAL_LOADER:
         return {
            ...state,
            isOperationalAreaLoading: true
         };
      case dataImportReducerConstant.REMOVE_OPERATIONAL_LOADER:
         return {
            ...state,
            isOperationalAreaLoading: false
         };
      case dataImportReducerConstant.UPDATE_TOOL_TYPE:
         return {
            ...state,
            toolTypeData: action.value
         };
      case dataImportReducerConstant.FETCH_DELIMITER_LIST:
         return {
            ...state,
            delimiterList: action.value
         };
      case dataImportReducerConstant.DELIMITER_DATA_ADDED:
         return {
            ...state,
            delimiterList: [...state.delimiterList, action.value]
         };
      case dataImportReducerConstant.SHOW_WARNING_DIALOG:
         return {
            ...state,
            isWarningDialogVisible: action.value.isWarningDialogVisible,
            warningMessage: action.value.warningMessage
         };
      case dataImportReducerConstant.SET_VERSIONING_LOADER:
         return {
            ...state,
            isVersionLoading: true
         };
      case dataImportReducerConstant.REMOVE_VERSIONING_LOADER:
         return {
            ...state,
            isVersionLoading: false
         };
      case dataImportReducerConstant.VALIDATING_ROWS:
         return {
            ...state,
            isValidationDialogVisible: action.value.isVisible,
            validationBodyContent: action.value.bodyData,
            validationExcelColumn: action.value.excelColumn ?? state.validationExcelColumn
         };
      case dataImportReducerConstant.UPDATE_FIELD_MAPPING:
         return {
            ...state,
            isForcedUpdateFieldMapping: action.value
         };
      case dataImportReducerConstant.UPDATE_RESULT_CONTENT:
         return {
            ...state,
            resultData: action.value
         };
      case dataImportReducerConstant.CLOSE_VALIDATION_DIALOG:
         return {
            ...state,
            isValidationDialogVisible: false
         };
      case dataImportReducerConstant.IS_REDUNDANT_PROCESS_REQUIRED:
         return {
            ...state,
            redundantProcessParams: action.value
         };
      case dataImportReducerConstant.IDR_WARNING_DATA:
         return {
            ...state,
            idrMismatchSheets: action.value
         };
      case dataImportReducerConstant.DISABLE_QC_BUTTONS:
         return {
            ...state,
            disableQCButtons: action.value
         };
      case dataImportReducerConstant.SAVE_DATA_ENTRY_VALUE:
         return {
            ...state,
            dataEntryValues: action.value
         };
      case dataImportReducerConstant.CLEAR_EXCEL_DATA:
         return {
            ...state,
            clearExcelData: action.value
         };
      default:
         return state;
   }
};

export default dataImportManageReducer;
