/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import { icons } from './assets/icons';
import store from './store';

React.icons = icons;

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <App />
      </Provider>
   </React.StrictMode>,
   document.getElementById('root'),
);
