/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer/rootReducer';

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
