/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import serviceCall from '../../store/serviceCall';
import {
   displayText, regularExpression, dataTypeCheck, stringConstant, apiRouter,
   tokenValidity, sessionStorageKey, errorMessage
} from '../../constant';
import { wizardStepper, fieldMappingSheetConfig, fieldCheck } from '../../dataimportconstants';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import moment from 'moment';
import _ from 'lodash';
import { arrayConstants } from '../../arrayconstants';
import { dataImportGrid } from '../../gridconstants';

export function preferenceIsActive(preference) {
   if (preference &&
      preference.isActive &&
      preference.isActive.toLowerCase() === displayText.FALSE) {
      return false;
   }
   return true;
}

export function getSteps() {
   return [
      wizardStepper.SELECT_FILE,
      wizardStepper.ILI_DATA_SUMMARY,
      wizardStepper.FIELDS_MAPPING,
      wizardStepper.QC_DASHBOARD,
   ];
}

export const deleteFile = fileName => {
   const url = `${apiRouter.FILE_UPLOAD}/${apiRouter.UPLOAD_CANCEL}?${apiRouter.DELETE_ORIGINAL_FILE}=${false}&${apiRouter.FILE_NAME}=${fileName}`;
   if (isNotEmptyNullUndefined(fileName)) {
      return serviceCall.postData(url, null);
   }
   return null;
};

export const fetchUnitConversion = async (value, currentUnit, targetUnit, unitType) => {
   const url = `${apiRouter.FIELD_MAPPING}/${apiRouter.GET_CONVERTED_VALUE}?value=${value}&sourceUnit=${currentUnit}&targetUnit=${targetUnit}&unitType=${unitType}`;
   if (isNotEmptyNullUndefined(value) &&
      isNotEmptyNullUndefined(currentUnit) &&
      isNotEmptyNullUndefined(targetUnit) &&
      isNotEmptyNullUndefined(unitType) &&
      currentUnit !== displayText.DEFAULT_PARENTID) {
      return await serviceCall.getAllData(url);
   }
   return { data: { data: value } };
};

export const addDelimiterData = delimiterName => {
   const addDelimiterUrl = `${apiRouter.EXCEL_IMPORT}/${apiRouter.ADD_DELIMITER}`;
   const delimiterData = { DelimiterName: delimiterName };
   return serviceCall.postData(addDelimiterUrl, delimiterData);
};

export function preferenceIsAsc(preference) {
   return preference === displayText.ASCENDING;
}

export function splitString(data) {
   return data?.split(stringConstant.SINGLE_SPACE_STRING);
}

export function isEmailValid(email) {
   return (email !== stringConstant.EMPTY_STRING &&
      regularExpression.EMAIL.test(String(email).toLowerCase()));
}

export function filterExcelResponse(keys) {
   const mainDataKeyName = _.find(keys, keyItem =>
      keyItem.toLowerCase().includes(fieldCheck.mainDataCheck.toLowerCase()) && !keyItem.toLowerCase().includes(fieldCheck.rowCountField)
   );
   const mainDataRowKeyName = _.find(keys, keyItem =>
      keyItem.toLowerCase().includes(fieldCheck.mainDataCheck.toLowerCase()) && keyItem.toLowerCase().includes(fieldCheck.rowCountField)
   );
   const genericDataKeyName = _.find(keys, keyItem =>
      keyItem.toLowerCase().includes(fieldCheck.genericDataField) && !keyItem.toLowerCase().includes(fieldCheck.rowCountField)
   );
   const genericDataRowKeyName = _.find(keys, keyItem =>
      keyItem.toLowerCase().includes(fieldCheck.genericDataField) && keyItem.toLowerCase().includes(fieldCheck.rowCountField)
   );
   return { mainDataKeyName, mainDataRowKeyName, genericDataKeyName, genericDataRowKeyName };
}

export function isNullUndefined(data) {
   return data !== null && data !== undefined;
}

export function isUndefined(data) {
   return data !== undefined;
}

export function isNotEmptyNullUndefined(data) {
   return data !== null &&
      data !== undefined &&
      data !== stringConstant.EMPTY_STRING;
}

export function isEmptyNullUndefined(data) {
   return data === null ||
      data === undefined ||
      data === stringConstant.EMPTY_STRING;
}

export function isNotEmpty(data) {
   return data?.trim() !== stringConstant.EMPTY_STRING;
}

export function isEmpty(data) {
   return data?.trim() === stringConstant.EMPTY_STRING;
}

export function isEmailFormatCorrect(email) {
   return !regularExpression.EMAIL.test(String(email).toLowerCase());
}

export function leftTrim(data) {
   return data?.replace(/^\s+/, stringConstant.EMPTY_STRING);
}

export function convertToISODate(date, format = displayText.ISO_DATE_FORMAT) {
   if(isEmptyNullUndefined(date)){
      return null;
   }
   return moment.utc(date).format(format);
}

export function convertToLocalDate(date, format = displayText.ISO_DATE_FORMAT) {
   return moment.utc(date).local().format(format);
}

export function formatDate(date) {
   return isNotEmptyNullUndefined(date) ? moment.utc(date) : stringConstant.EMPTY_STRING;
}

export function formatDateIntoReadable(date) {
   return isNotEmptyNullUndefined(date) ? moment(date).format(displayText.DATE_FORMAT) : stringConstant.EMPTY_STRING;
}

export function isDotEmpty(data) {
   return data?.trim() !== stringConstant.EMPTY_STRING && data !== stringConstant.DOT_OPERATOR;
}

export function isNotNull(data) {
   return data !== null;
}

export function removeCharacter(data) {
   return data.replace(/[^\d.-]/g, stringConstant.EMPTY_STRING);
}

export function convertToValidPrecisionNumber(precision, scale, data) {
   if (isEmptyNullUndefined(data)) {
      return [stringConstant.EMPTY_STRING, true];
   }
   const splittedDecimal = data.includes(stringConstant.DOT_OPERATOR)
      ? data.split(stringConstant.DOT_OPERATOR)
      : [data, stringConstant.EMPTY_STRING];
   const beforeDecimalCount = _.head(splittedDecimal)?.length;
   const afterDecimalCount = _.last(splittedDecimal)?.length;

   if (scale === fieldMappingSheetConfig.scaleValue) {
      return (beforeDecimalCount <= precision && afterDecimalCount <= scale)
         ? [removeCharacter(removeSpecialCharacterExceptDot(data)), true]
         : [removeCharacter(removeSpecialCharacterExceptDot(data)), false];
   }
   if (splittedDecimal.length > fieldMappingSheetConfig.decimalDataLengthCheck) {
      let decimalCopy = data.toString();
      const decimalCopySplit = data.includes(stringConstant.DOT_OPERATOR)
         ? decimalCopy.split(stringConstant.DOT_OPERATOR)
         : [data, stringConstant.EMPTY_STRING];
      const decimalDigits = _.last(decimalCopySplit);
      const realDigits = _.head(decimalCopySplit);

      if (isNotEmptyNullUndefined(decimalDigits)) {
         decimalCopy = removeSpecialCharacterExceptDot(decimalDigits);
         data = `${realDigits}.${decimalCopy}`;
      }
      return beforeDecimalCount <= precision - scale &&
         afterDecimalCount <= scale
         ? [removeCharacter(data), true]
         : [removeCharacter(data), false];
   }
   return beforeDecimalCount <= precision - scale
      ? [removeCharacter(data), true]
      : [removeCharacter(data), false];
}

export function isStatusCodeValid(result, statusCode) {
   if (isUndefined(result?.status) && (result?.status === statusCode)) {
      return true;
   }
   return false;
}

export function setSessionStorage(key, value) {
   return encryptData(value, key);
}

export function autoCompleteOff(event) {
   event.target.setAttribute(displayText.AUTO_COMPLETE, displayText.NEW_PASSWORD_ATTRIBUTE);
}

export function isArrayContainsObject(data) {
   return (
      isNotEmptyNullUndefined(data) &&
      data.length > fieldMappingSheetConfig.fieldMapLengthCheck &&
      typeof (_.head(data)) === dataTypeCheck.OBJECT);
}

export function isDataAnObject(data) {
   if (isEmptyNullUndefined(data)) {
      return false;
   }
   if (Array.isArray(data) && data.length > fieldMappingSheetConfig.fieldMapLengthCheck) {
      return true;
   }
   if (typeof (data) === dataTypeCheck.OBJECT && !Array.isArray(data)) {
      return true;
   }
   return false;
}

export function removeSpecialCharacter(value) {
   return value
      .replace(/[*|\":<>[\]{}`\\()';@&$#^%_+!&?/,]/g, stringConstant.EMPTY_STRING)
      .replace(/\./g, stringConstant.EMPTY_STRING);
}

export function removeSpecialCharacterExceptDot(value) {
   return value.replace(/[*|\":<>[\]{}`\\()';@&$#^%_+!&?/,]/g, stringConstant.EMPTY_STRING);
}

export function handleCloseStateCountry(propDetails) {
   return isNotEmptyNullUndefined(propDetails) ? propDetails : stringConstant.EMPTY_STRING;
}

export function removeMultipleSpace(data) {
   return data.replace(/  +/g, stringConstant.SINGLE_SPACE_STRING);
}

export function findFeatures(feature, name) {
   return isNullUndefined(_.find(feature, featureItem =>
      featureItem.name === name
   ));
}

export function checkFieldLength(length, condition) {
   return length > condition;
}

export function findFeaturesRole(feature, name) {
   return _.find(feature, featureItem =>
      featureItem.name === name
   );
}

export function encryptData(data, key) {
   const encryptedData = CryptoAES.encrypt(JSON.stringify(data), key);
   localStorage.setItem(key, encryptedData);
   sessionStorage.setItem(key, encryptedData);
}

export function decryptData(key) {
   const localStorageData = (isNotNull(sessionStorage.getItem(key))) ? sessionStorage.getItem(key) : localStorage.getItem(key);
   if (!isNotEmptyNullUndefined(localStorageData)) {
      return null;
   }
   const cipherText = CryptoAES.decrypt(localStorageData.toString(), key);
   return (cipherText.toString(CryptoENC));
}

export function checkCookieIsPresent(cname) {
   const name = cname + stringConstant.ASSIGNTO_OPERATOR;
   const ca = document.cookie.split(stringConstant.SEMICOLON_OPERATOR);
   for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === stringConstant.SINGLE_SPACE_STRING) {
         c = c.substring(1);
      }
      if (c.indexOf(name) === 0 && (name.length !== c.length)) {
         return { isTokenAvailable: true, tokenValue: c.substring(name.length, c.length) };
      }
   }
   return { isTokenAvailable: false, tokenValue: null };
}

export function isCookieValid() {
   if (isNullorUndefined(document.cookie) || isEmpty(document.cookie)) {
      clearStorageItems();
      return false;
   }
   const { tokenValue } = checkCookieIsPresent(sessionStorageKey.TOKEN);
   if (isEmptyNullUndefined(tokenValue) || isEmptyNullUndefined(tokenValue.split(stringConstant.DOT_OPERATOR)[arrayConstants.tokenData])){
      clearStorageItems();
      return false;
   }
   const jwtToken = JSON.parse(atob(tokenValue?.split(stringConstant.DOT_OPERATOR)[arrayConstants.tokenData]));
   const expires = jwtToken.exp;
   if (isNotEmptyNullUndefined(expires) && Date.now() <= expires * tokenValidity.convertToMilliSeconds) {
      return true;
   }
   clearStorageItems();
   return false;
}

export function isNullorUndefined(data) {
   return data === null || data === undefined;
}

export function getDirection(header, orderBy, isAsc) {
   if (orderBy === header.sort && !isAsc) {
      return displayText.DESC;
   }
   return displayText.ASCENDING;
}

export function removeDoubleQuotes(value) {
   return value.replace(regularExpression.REMOVE_DOUBLE_QUOTES, stringConstant.EMPTY_STRING);
}

export function removeCookie() {
   document.cookie = `${sessionStorageKey.TOKEN}=`;
}

export function clearStorageItems() {
   sessionStorage.clear();
   return localStorage.clear();
}

export function isSendInvite(user) {
   if (isNullorUndefined(user) ||
      isNullorUndefined(user?.isUserActivationTokenExpired) ||
      isNullorUndefined(user?.isUserActivated)) {
      return false;
   }
   return (user?.isUserActivationTokenExpired &&
      !user?.isUserActivated);
}

export const fetchSheetName = (sheetData, sheetFullName) => {
   if (isNullUndefined(sheetData) && isNullUndefined(sheetFullName)) {
      const splittedSheetName = _.head(sheetFullName).split(stringConstant.UNDERSCORE_OPERATOR);
      splittedSheetName.pop();
      return splittedSheetName.join(stringConstant.UNDERSCORE_OPERATOR);
   }
   return stringConstant.EMPTY_STRING;
};

export function resizerHeightReset(currentSheetSelected) {
   setTimeout(() => {
      var splitTable = document.getElementsByClassName('sheetTabPanel');
      if (splitTable && splitTable.length > arrayConstants.nonEmptyArray &&
         isNotEmptyNullUndefined(currentSheetSelected?.genericRows) &&
         isNotEmptyNullUndefined(currentSheetSelected?.rows) &&
         currentSheetSelected.rows.length > arrayConstants.nonEmptyArray &&
         currentSheetSelected.genericRows.length > arrayConstants.nonEmptyArray) {
         for (let i = arrayConstants.initialOrder; i < splitTable.length; i++) {
            if (splitTable[i].childNodes &&
               isNotEmptyNullUndefined(splitTable[i].childNodes[arrayConstants.initialOrder]) &&
               !splitTable[i].childNodes[arrayConstants.initialOrder].className.includes('tableFullHeight')) {
               splitTable[i].childNodes[arrayConstants.initialOrder].className += ' tableFullHeight';
            }
         }
      }

      if (isNotEmptyNullUndefined(currentSheetSelected?.genericRows) &&
         currentSheetSelected?.genericRows.length > arrayConstants.nonEmptyArray &&
         currentSheetSelected?.rows.length > arrayConstants.nonEmptyArray) {
         const totalLength = ((currentSheetSelected.genericRows.length + dataImportGrid.GenericRowHeader) * dataImportGrid.HeaderPixel) + dataImportGrid.HeaderBuffer;
         document.getElementsByClassName('header-data-grid')[arrayConstants.initialOrder] &&
         (document.getElementsByClassName('header-data-grid')[arrayConstants.initialOrder].style.maxHeight = `${totalLength}px`);
      }

      var resizer = document.getElementsByClassName('ResizePanel-module_ResizeContent__1zLCK ResizePanel-module_ResizeContentVertical__1OGHY')[arrayConstants.initialOrder];
      if (isNotEmptyNullUndefined(resizer)) {
         resizer.style.height = `${dataImportGrid.DefaultHeight}px`;
      }
   }, fieldMappingSheetConfig.stateUpdateDelay);
}

export function isResultContainsData(data) {
   return (
      isNotEmptyNullUndefined(data) &&
      data.length > fieldMappingSheetConfig.fieldMapLengthCheck);
}

export const isLengthGreaterThanZero = (data, rowCount = fieldMappingSheetConfig.defaultRowCount) => {
   if (data?.length > rowCount) {
      return true;
   }
   return false;
};

export const renderArrayBasedString = array => {
   return array.reduce((a, b, i, parameter) => a + (i < parameter.length - fieldMappingSheetConfig.counterInitialValue
      ? `${stringConstant.COMMA_OPERATOR}${stringConstant.SINGLE_SPACE_STRING}`
      : ` ${displayText.AND} `) + b);
};

export const convertRowsToSingleObject = rows => {
   return rows.map(currentRow =>
      convertArrayToSingleObject(currentRow)
   );
};

const convertArrayToSingleObject = array => {
   return _.transform(array, (resultObject, keyValuePair) =>
      (resultObject[keyValuePair.key] = keyValuePair.value, resultObject)
      , {});
};

export const isLengthEqualsToValue = (array, lengthValue = arrayConstants.nonEmptyArray) => array.length === lengthValue;

export const isFailureSnackbarWarningEmpty = warningContent => {
   if (typeof warningContent === dataTypeCheck.OBJECT) {
      return errorMessage.API_CALL_DEFAULT_ERROR;
   }
   if (isEmptyNullUndefined(warningContent)) {
      return errorMessage.API_CALL_DEFAULT_ERROR;
   }
   return warningContent;
};

export const handleCheckNameExists = (addUrl, editUrl, action, clientId) => {
   let checkEmailUrl = addUrl;
   if (action !== displayText.ADD) {
      checkEmailUrl = `${checkEmailUrl}&${editUrl}`;
   }
   return serviceCall.getAllLookupData(checkEmailUrl, clientId);
};

export const renderHelperText = (name, isShowError, checkNameExists) => {
   if (name === stringConstant.EMPTY_STRING && isShowError) {
      return errorMessage.PLEASE_ENTER_NAME;
   }
   if (checkNameExists && isShowError) {
      return errorMessage.NAME_ALREADY_EXIST;
   }
   return stringConstant.EMPTY_STRING;
};

export const checkNameValidation = async (action, setLoaderText, addUrl, editUrl, clientId, isNameExistFunction, isNameDoesntExistFuncation) => {
   if (action !== displayText.ADD) {
      return null;
   }
   setLoaderText(displayText.FETCHING_USER_DATA);
   const isNameExists = await handleCheckNameExists(
      addUrl, editUrl, action, clientId
   );
   if (isNameExists?.data) {
      return isNameExistFunction(isNameExists);
   }
   isNameDoesntExistFuncation();
};
