/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
export const wizardStepper = {
   FIELDS_MAPPING: '3. Field Mapping',
   ILI_DATA_SUMMARY: '2. ILI Data Summary',
   QC_DASHBOARD: '4. QC Dashboard',
   SELECT_FILE: '1. Select File'
};

export const fieldMappingSheetConfig = {
   chunkCalculateByHundred: 100,
   chunkDefaultStepCounter: 0.1,
   chunkDefaultFinalStepCounter: 0.01,
   qcProgressInterval: 10,
   chunkQcProgress: 98,
   chunkCountOne: 1,
   counterInitialValue: 1,
   dataGridColumnWidth: 150,
   dataImportScreen: 0,
   datePickerCloseTimeout: 1000,
   removeUnwantedRenderTimeout: 2000,
   dataTableDefaultWidth: 200,
   decimalDataLengthCheck: 1,
   defaultFileSize: 0,
   defaultQcInputRadioValue: '2',
   defaultCounterValue: 0,
   defaultRowCount: 0,
   defaultSheetIndex: 0,
   defaultTabIndex: 0,
   doubleSpaceLength: 2,
   diameterPrecision: 8,
   diameterScale: 4,
   seamFactorPrecision: 2,
   seamFactorScale: 1,
   excelHeaderDataRowCount: 1,
   excelMainDataRows: 2,
   minimumFieldMapLength: 0,
   fieldMappingRowSlice: 0,
   fieldMappingScreen: 2,
   fieldMapRadioValue: '0',
   fieldMapLengthCheck: 0,
   fillInRadioValue: '1',
   fileUploadIndex: 1,
   headerColumnWidth: 300,
   iliDataCommentsLocationLimit: 255,
   iliDataModel: 32,
   iliDataResolutionLimit: 16,
   iliDataSummaryScreen: 1,
   incrementStepper: 1,
   initialIterationValue: 0,
   invalidIndex: -1,
   lazyLoadCallCondition: 200,
   lazyLoadingInitiator: 150,
   lazyLoadInitialStartLimit: 0,
   occuranceLengthCheck: 1,
   pressurePrecision: 5,
   pressureScale: 0,
   previewDataInitialLimit: 50,
   progressBarConclude: 100,
   progressBarInitialValue: 0,
   progressBarFinalStage: 100,
   qcDashboardCurrentVersionSwitch: 1,
   qcDashboardPreviousVersionSwitch: 0,
   qcDashboardScreen: 3,
   qcDashboardTextFieldRows: 4,
   scaleValue: 0,
   singleSpaceLength: 1,
   singleObjectArrayLength: 1,
   sliceExcelAbbreviation: 1,
   sliceExcelAbbreviationElement: -1,
   spacingMaxMinPrecision: 6,
   spacingScale: 4,
   spliceIndex: 1,
   splitScreenMaximumSize: 150,
   splitScreenMinimumSize: 100,
   stateUpdateDelay: 500,
   temperaturePrecision: 4,
   temperatureScale: 0,
   timeIntervalLimit: 20000,
   tripleSpaceLength: 3,
   velocityPrecision: 7,
   velocityWeightScale: 3,
   versionDropdownLimit: 1,
   versionNotesBoxMargin: 1,
   versionNotesColumnSplit: 14,
   versionPrimaryMinSize: 60,
   versionSecondaryMinSize: 150,
   versionSecondaryMinSizeNoData: 50,
   weightPrecision: 8
};

export const tabIndices = {
   defaultTabIndex: 0,
   qcDashboardDefaultTabIndex: 1
};

export const fieldCheck = {
   errorDescription: 'ERRORDESCRIPTION',
   errorDescriptionFieldName: 'Error Description',
   fieldCountCheck: 3,
   genericDataField: 'genericdata',
   initialFieldCount: 0,
   initialLabelData: 0,
   mainDataCheck: 'MainData',
   joinColumnText: ', ',
   rowCountField: 'rowcount'
};

export const qcDashboardCheck = {
   countOf: 'Count of',
   excelTemplate: 'excelTemplate',
   dataSetBorderWidth: 1,
   graphDataHeight: 3,
   graphMinimumHeight: 5,
   graphMinimumTick: 0,
   graphTitleFontSize: 15,
   isAlert: '_alert',
   isError: '_error',
   isInformational: '_informational',
   isTable: '_Table',
   jointLength_Key: 'jointlength_key',
   lastJointLength_Key: 'lastjointlength_key',
   splitParameter: '_',
   stringType: 'string'
};
