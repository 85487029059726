/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { overviewDashboardReducerConstant } from '../../reducerConstant';
import _ from 'lodash';
import { isLengthGreaterThanZero } from '../../../components/shared/helper';

const initialState = {
   operationalAreas: [],
   vendors: [],
   iliInspections: [],
   toolTypes: [],
   searchLoader: false,
   searchLoaderMessage: null,
   searchResults: [],
   isSearchDataRecieved: false,
};

const searchReducer = (state = initialState, action = {}) => {
   const { type, value } = action;
   switch (type) {
      case overviewDashboardReducerConstant.GET_DEFAULT_FIELD_DATA:
         return {
            ...state,
            operationalAreas: value.operationalAreaDto ?? state.operationalAreas,
            vendors: value.vendorDto ?? state.vendors,
            toolTypes: isLengthGreaterThanZero(state.toolTypes) ? state.toolTypes : value.toolTypeDto,
            iliInspections: value.projectNames ?? state.iliInspections,
            searchLoader: false
         };
      case overviewDashboardReducerConstant.SEARCH_LOADER_STATE_UPDATE:
         return {
            ...state,
            searchLoader: value.isLoaderVisible,
            searchLoaderMessage: value.loaderMessage
         };
      case overviewDashboardReducerConstant.GET_SEARCH_RESULTS:
         return {
            ...state,
            searchResults: value,
            isSearchDataRecieved: true,
            searchLoader: false,
            searchLoaderMessage: null
         };
      case overviewDashboardReducerConstant.RESET_DEFAULT_STATE:
         return {
            ...state,
            searchResults: [],
            isSearchDataRecieved: false,
            searchLoader: false,
            searchLoaderMessage: null
         };
      default:
         return state;
   }
};

export default searchReducer;
