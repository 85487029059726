/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
export const labelWidth = {
   toolType: 50,
   operationalArea: 130,
   vendor: 50,
   projectName: 20,
   fromLocation: 20,
   toLocation: 50
};

export const dropDownWidth = {
   LG: 2,
   MD: 3,
   SM: 4,
   XS: 12
};

export const tableHeader = {
   INSPECTION_ID: 'Inspection ID',
   VERSION_ID: 'Version ID',
   PROJECT_NAME: 'Project Name',
   FROM_LOCATION: 'From Loc',
   TO_LOCATION: 'To Loc',
   VENDOR: 'Vendor',
   TOOL_TYPE: 'Tool Type',
   RUN_START_DATE: 'RunStartDate',
   RUN_END_DATE: 'RunEndDate',
   IMPORT_DATE: 'Import Date',
   STATUS: 'Status'
};

export const searchDisplayText = {
   ANALYSIS_DASHBOARD: 'Analysis Dashboard',
   ALL_FILTER_VALUE: 'All',
   CLUSTER: 'Cluster',
   DIMENSION_TEXT: 'Measurement Dimension',
   DISTANCE: 'Distance',
   FEATURE_CATEGORY: 'featureCategory',
   FEATURE_TYPE: 'featureType',
   FEATURE_TYPE_COUNT: 'featureTypeCount',
   INTERNAL_FILTER_VALUE: 'Internal',
   EXTERNAL_FILTER_VALUE: 'External',
   INVALID: 'Invalid',
   LENGTH_FILTER_VALUE: 'Length',
   DEPTH_FILTER_VALUE: 'Depth',
   OVERVIEW_TAB_VALUE: 'overview',
   OVERVIEW_DISPLAY_VALUE: 'Overview',
   METAL_LOSS_TAB_VALUE: 'metalLoss',
   METAL_LOSS_DISPLAY_VALUE: 'Metal Loss',
   MILL_DEFECT: 'Mill Defect',
   DEFORMATION_TAB_VALUE: 'deformation',
   DEFORMATION_DISPLAY_VALUE: 'Deformation',
   MAP_TAB_VALUE: 'map',
   MAP_DISPLAY_VALUE: 'Map',
   TOTAL_RECORDS: 'Total Records:',
   VALID: 'Valid',
   WALL: 'Wall Surface',
   WIDTH_FILTER_VALUE: 'Width'
};

export const chartTypes = {
   SCATTER_PLOT: 'scattergl',
   SCATTER_NON_GL_PLOT: 'scatter',
   BAR: 'bar',
   HISTOGRAM: 'histogram',
   PIE: 'pie'
};

export const chartHorizontalOrientation = 'h';

export const chartColorCodes = {
   GREEN: '#008000',
   BLUE: '#0000ff',
   RED: '#ff0000',
   YELLOW: '#ffff00',
   PINK: '#ffc0cb',
   ORANGE: '#ffa500',
   BLACK: '#000000',
   ULTRAVIOLET: '#bb5415',
   BLOOMINGDAHLIA: '#64204d',
   LIGHTBLUE: '#9FDEF1',
   WARMSAND: '#320064',
   DEEPBLUE: '#881e0c',
   DEEPORANGE: '#F95700FF',
   SAILORBLUE: '#c90aed',
   MINT: '#04813c',
   GRAY: '#606060FF',
   LIMEPUNCH: '#901c97',
   SEABLUE: '#17BECF',
   DARKBLUE: '#1F77B4',
   DARKRED: '#D62728',
   GREENISHBLUE: '#1F77B4',
   PINKISHRED: '#E377C2'
};

export const defaultColorCodes = [
   '#bb5415',
   '#64204d',
   '#320064',
   '#881e0c',
   '#F95700FF',
   '#c90aed',
   '#04813c',
   '#606060FF',
   '#901c97',
   '#9FDEF1'
];

export const defaultColorCodeForPie = [
   '#6197E2',
   '#B9E52F',
   '#863CFF',
   '#30CB71'
];

export const chartModes = {
   LINES: 'lines',
   LINE_MARKERS: 'lines+markers',
   MARKERS: 'markers',
   STACK: 'stack'
};

export const chartProperties = {
   DEFAULT_BIN_SIZE: 30,
   DEFAULT_OPACITY: 1,
   DEFAULT_RANGE: [0, 360],
   DEFAULT_BAR_GAP: 0.05,
   DEFAULT_FONT_SIZE: 16,
   DEFAULT_TICKS: 10,
   ORIENTATION_END_VALUE: 360,
   DEFAULT_TICK_FORMAT: '.2f',
   LENGTH_CHART_OPACITY: 0.4,
   LENGTH_CHART_SHAPE: 'hv',
   LEGEND_VISIBILITY: 'legendonly',
   CHART_TICK_MODE: 'linear',
   CHART_START_TICK: 0
};

export const chartTitles = {
   HISTOGRAM_ORIENTATION: 'HISTOGRAM OF ORIENTATION',
   HISTOGRAM_MEASUREMENTS: 'HISTOGRAM OF MEASUREMENTS',
   MEASUREMENT_STATISTICS: 'MEASUREMENT STATISTICS',
   HISTOGRAM_FEATURES: 'HISTOGRAM OF DEFECT FEATURES',
   FAILURE_PRESSURE: 'Failure Pressure',
   FEATURE_COUNT: 'Feature Count',
   RAW: 'Raw',
   FEATURE_SUMMARY: 'FEATURE SUMMARY',
   FEATURE_TYPE: 'Feature Type',
   FEATURE_CATEGORY: 'Feature Category',
   DOWNSTREAM_WELD_DISTANCE: 'Downstream Weld Distance', 
   FEATURE_DATA:'FEATURE DATA',
   FEATURE_DISTRIBUTION: 'DEFECT FEATURE DISTRIBUTION',
   ORIENTATION: 'Orientation(0-360 Degree)',
   JOINT_NUMBER: 'Joint Number',
   JOINT_LENGTH: 'Joint Length',
   DISTANCE_TABLE_COLUMN: 'Distance',
   WALL_THICKNESS_TABLE_COLUMN: 'Wall Thickness',
   ORIENTATION_TABLE_COLUMN: 'Orientation(hh:mm)',
   OVERVIEW_TABLE: 'TABLE FOR OVERVIEW',
   FEATURE_NUMBER: 'Feature Number',
   UPSTREAM_WELD: 'Upstream Weld Distance',
   METAL_LOSS_INTERNAL: 'Metal Loss-Internal',
   METAL_LOSS_EXTERNAL: 'Metal Loss-External',
   METAL_LOSS_ORIENTATION: 'METAL LOSS ORIENTATION',
   METAL_LOSS_TABLE: 'TABLE FOR METAL LOSS',
   CLUSTER_INTERNAL: 'Cluster-Internal',
   CLUSTER_EXTERNAL: 'Cluster-External',
   DEFORMATION_DENT: 'Deformation Dent',
   DEFORMATION_OTHER: 'Deformation Other',
   DEFORMATION_ORIENTATION: 'DEFORMATION ORIENTATION',
   DEFORMATION_TABLE: 'TABLE FOR DEFORMATION',
   DISTANCE: 'Distance',
   LENGTH: 'Length',
   WIDTH: 'Width',
   DEPTH: 'Depth %',
   COUNT: 'Count',
   WALL_THICKNESS: 'Wall Thickness',
   WALL_THICKNESS_CHANGES: 'WALL THICKNESS CHANGES',
   RPR_PIG: 'RPR',
   IDR: 'IDR',
   SMYS: 'SMYS',
   MOP: 'MOP',
   PIPE_OUTER_DIAMETER: 'Pipe Outside Diameter',
   COMMENTS: 'Comments',
   MEDIAN: 'Median',
   MIN: 'Min',
   MAX: 'Max',
   MEAN: 'Mean',
   FAILURE_PRESSURE_PLOT: 'Pressure Plot',
   SAFE_OPERATING_PRESSURE:'Safe Operating Pressure (kPa)',
   CALCULATED_FAILURE_PRESSURE:'Calculated mB31G Failure Pressure (kPa)',
   LICENSED_MOP:'MOP',
   SAFETY_PRESSURE_THRESHOLD:'Safety Factor Threshold',
   NORMAL_OPERATING_PRESSURE:'Normal Operating Pressure',
   ODOMETER: 'Odometer',
   PRESSURE: 'Pressure'
};

export const tableFieldNames = {
   DOWNSTREAM_WELD_DISTANCE:'downstreamWeldDistance',
   JOINT_NUMBER: 'joinWeldNumber',
   FAILURE_PRESSURE: 'failurePressure',
   FEATURE_NUMBER: 'featureNumber',
   FEATURE_CATEGORY: 'featureCategory',
   FEATURE_TYPE: 'featureType',
   JOINT_LENGTH: 'jointLength',
   FEATURE_LENGTH: 'featureLength',
   FEATURE_WIDTH: 'featureWidth',
   DISTANCE: 'distance',
   DEPTH_PERCENT: 'depthPercentage',
   WALL: 'wall',
   WALL_THICKNESS: 'wallThickness',
   ORIENTATION: 'clockOrientation',
   UPSTREAM_DISTANCE: 'upstreamWeldDistance',
   INSPECTION_NUMBER: 'inspectionNumber',
   VERSION_NUMBER: 'versionNumber',
   PROJECT_NAME: 'projectName',
   FROM_LOCATION: 'fromLocation',
   TO_LOCATION: 'toLocation',
   VENDOR: 'vendor',
   RPR_PIG: 'rpr',
   IDR: 'idr',
   SMYS: 'smys',
   MOP: 'mop',
   PIPE_OUTER_DIAMETER: 'pipeOuterDiameter',
   COMMENTS: 'comments',
   TOOL_TYPE: 'toolType',
   RUN_START_DATE: 'runStartDate',
   RUN_END_DATE: 'runEndDate',
   IMPORT_DATE: 'importDate',
   STATUS: 'status'
};

export const toolVendorGuid = 'iliToolVendorClGuid';

export const operationalAreaGuid = 'iliToolOperationalareaClGuid';

export const defaultHeightMax = 'calc(100vh - 370px)';

export const defaultHeightMin = 400;

export const paperElevation = 3;

export const paperSpacing = 2;

export const stepValue = 0.00001;

export const timeOutForTabSwitch = 500;
