/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */

export const defaultPlotlyFontSize = 12;
export const defaultDocSize = 'A4';
export const csvFileExtension = '.csv';
export const errorMessage = {
   API_CALL_DEFAULT_ERROR: 'An error has occurred! Please contact administrator.',
   BOTH_PASSWORD_SHOULD_MATCH: 'New Password and Confirm Password should match',
   CANCELING_UPLOADING: 'Canceling file uploading...',
   CANNOT_REVERT: 'Cannot revert before saving',
   CHUNK_UPLOAD_FAILED: 'Something went wrong while upload retry again',
   CLIENT_ALREADY_EXISTS: 'Client already exists',
   DATA_NOT_FOUND: 'Something went wrong, data not found...',
   EMAIL_ALREADY_EXISTS: 'Email already exists',
   FILE_FORMAT_NOT_ALLOWED: 'This file format is not allowed',
   HAS_DUPLICATE_COLUMN: ' has duplicate table column.',
   INTERNAL_SERVER_ERROR: 'Internal server error.',
   INVALID_PASSWORD: 'Invalid Credentials',
   IS_OLD_PASSWORD_VALID: 'The entered old password isn\'t valid',
   INVALID_DELIMITER_MESSAGE: 'The selected delimiter in the following',
   LOGIN_ERROR: 'Please enter the correct email  and password',
   LINK_EXPIRED_ERROR_MESSAGE: 'Request failed with status code 410',
   NAME_ALREADY_EXIST: 'Name already exists',
   OLDPASSWORD_AND_NEWPASSWORD_NOT_SAME: 'Old password and new password should not same.',
   OLD_PASSWORD_IS_SAME_AS_CURRENT_PASSWORD: 'The given password shouldn\'t match with the previous password.',
   PASSWORD_NOT_MATCH_REQUIREMENT: 'The new password does not meet the password policy requirements',
   PLEASE_COMPLETE_FIELD_MAPPING: 'Please complete the field mapping',
   PLEASE_COMPLETE_FIELDMAPPING_TO_CONTINUE: 'Please complete the field mapping to continue',
   PLEASE_CONTACT_ADMIN: 'Please contact Cenozon support at support@cenozon.com',
   PLEASE_ENTER_BOTH_PASSWORD: 'Please enter the new password & confirm password',
   PLEASE_ENTER_CLIENT_NAME: 'Please enter the client name',
   PLEASE_ENTER_CONFIRM_PASSWORD: 'Please enter the confirm password',
   PLEASE_ENTER_CORRECT_MAIL_FORMAT: 'Please enter the correct email format',
   PLEASE_ENTER_DIAMETER: 'Please enter the outer diameter',
   PLEASE_ENTER_DESCRIPTION: 'Please enter Description',
   PLEASE_ENTER_EMAIL: 'Please enter the email',
   PLEASE_ENTER_MAIL_ID: 'Please enter the email',
   PLEASE_ENTER_MAIL_ID_AND_PASSWORD: 'Please enter the email and password',
   PLEASE_ENTER_MAXIMUM_PRESSURE: 'Please enter the maximum operating pressure',
   PLEASE_ENTER_MINIMUM_STRENGTH: 'Please enter the specified minimum yield strength',
   PLEASE_ENTER_NAME: 'Please enter the name',
   PLEASE_ENTER_NEW_PASSWORD: 'Please enter the new password',
   PLEASE_ENTER_OLD_PASSWORD: 'Please enter the old password',
   PLEASE_ENTER_PASSWORD: 'Please enter the password',
   PLEASE_ENTER_ROLE_NAME: 'Please enter the role name',
   PLEASE_ENTER_USERNAME: 'Please enter the user name',
   PLEASE_ENTER_VALID_USERNAME: 'Please enter a valid username',
   PLEASE_ENTER_VENDOR_NAME: 'Please enter the vendor name',
   PLEASE_GIVE_VALID_INPUT: 'Please fill the valid data',
   PLEASE_NAVIGATE_TO_FIRST_STEP: 'Please navigate to first step',
   PLEASE_PROVIDE_DELIMITER: 'Please provide a delimiter',
   PLEASE_SAVE_TO_VIEW_SUMMARY_DASHBOARD: 'Please save your data to view ILI Summary Dashboard',
   PLEASE_PROVIDE_INDEX_DESCRIPTION: 'Please enter the index description',
   PLEASE_SELECT_BEGIN_DATE: 'Please select the begin date',
   PLEASE_SELECT_DELIMITER: 'Please select the delimiter',
   PLEASE_SELECT_END_DATE: 'Please select the end date',
   PLEASE_SELECT_ONE_VERSION: 'Please select a single ILI Inspection',
   PLEASE_SELECT_ROLE: 'Please select atleast one role',
   PLEASE_SELECT_TABLE_COLUMN: 'Please select Table Column for ',
   PLEASE_SELECT_TABLE_COLUMN_ONE: 'Please select the Table Column 1',
   PLEASE_SELECT_TABLE_COLUMN_TWO: 'Please select the Table Column 2',
   PLEASE_SELECT_SUB_UNIT: 'Please select the sub unit',
   PLEASE_SELECT_UNIT: 'Please select the unit',
   PLEASE_SELECT_VENDOR: 'Please select the vendor',
   PLEASE_SELECT_VERSION: 'Please select a version to override',
   PLEASE_UPLOAD_EXCEL: 'Please upload the file',
   PLEASE_SELECT_VALID_SOURCE: 'Please select a valid source',
   PLEASE_SELECT_VALID_INTERNAL_EXTERNAL_CL: 'Please select a valid internal external',
   PLEASE_SELECT_VALID_ANOMALY_CLASSIFICATION: 'Please select a valid anomaly classification',
   PLEASE_UPLOAD_VALID_EXCEL: 'Please upload a valid file',
   PLEASE_WAIT_UNTIL_UPLOAD: 'Please wait until file has been uploaded',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_4_0: 'Please give the valid input with (4,0) precision',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_MAOP_COLUMN: 'Please provide at most 5 digits for maximum operating pressure',
   PLEASE_PROVIDE_VALID_POSITIVE_INPUT_MAOP_COLUMN: 'Please enter the positive value for maximum operating pressure',
   PLEASE_PROVIDE_VALID_POSITIVE_INPUT_SMYS_COLUMN: 'Please enter the positive value for specified minimum yield strength',
   PLEASE_PROVIDE_VALID_POSITIVE_INPUT_OD_COLUMN: 'Please enter the positive value for outer diameter',
   PLEASE_PROVIDE_VALID_POSITIVE_INPUT_SEAM_FACTOR: 'Please enter the positive value for seam factor',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_6_4: 'Please give the valid input with (6,4) precision',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_7_3: 'Please give the valid input with (7,3) precision',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_8_3: 'Please give the valid input with (8,3) precision',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_SEAM_FACTOR_COLUMN: 'Please provide at most 1 digits before and after decimal point for seam factor',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_OD_COLUMN: 'Please provide at most 4 digits before and after decimal point for outer diameter',
   PLEASE_PROVIDE_VALID_INPUT_PRECISION_SMYS_COLUMN: 'Please provide at most 5 digits for specified minimum yield strength',
   PLEASE_SELECT_UNIT_AND_SUB_UNIT: 'Please select unit and sub unit before saving',
   PLEASE_SAVE_INSPECTION: 'Please save the inspection to view analysis dashboard.',
   SEARCH_DEFAULT_FILTER_SELECTION: 'Please select at least one filter',
   ROLE_ALREADY_EXISTS: 'Role already exists',
   TABLE_COLUMN_IS_DUPLICATE: 'Table Columns 1 & 2 has duplicate table Columns',
   UNABLE_TO_PARSE_EXCEL: 'Error in parsing the file',
   UNABLE_TO_REVERT: 'Unable to revert the action',
   UNABLE_TO_UPDATE_MATCHING_DETAILS: 'Unable to update matching details',
   UNABLE_TO_FETCH_SUMMARY_DETAILS: 'Unable to fetch ILI Summary Details',
   UNABLE_TO_FETCH_QC_DASHBOARD: 'Unable to load QC Dashboard',
   UNABLE_TO_FETCH_DELIMITERS: 'Unable to fetch Delimiters',
   UPLOAD_CANCELLED_FAIL: 'Cancel upload failed',
   UNIT_MISMATCH: 'The target unit',
   UNIT_MISMATCH_TARGET: 'does not matched with the selected unit',
   UPLOAD_CANCELLED_SUCCESS: 'Upload cancelled successfully',
   UPLOAD_COMPLETE_FAIL: 'Upload complete failed',
   VENDOR_ALREADY_EXISTS: 'Vendor already exists'
};

export const displayText = {
   ACTIVE: 'Active',
   ACCOUNT: 'Account',
   ADD: 'Add',
   ADDRESS: 'Address',
   ADD_APP_ADMIN: 'Add Application Administrator',
   ADD_CLIENT: 'Add Client',
   ADD_OPERATIONALAREA_ROLE: 'Add Area',
   ADD_OPERATIONAL_AREA: 'Add Operational Area',
   ADD_INTERNAL_EXTERNAL_CHILD: 'Add Internal External Child',
   ADD_PIPE_SEGMENT_MATERIAL: 'Add Pipe Segment Material',
   ADD_PIPE_LONG_SEAM: 'Add Pipe Long Seam',
   ADD_PIPE_GRADE: 'Add Pipe Grade',
   ADD_TOOL_TYPE: 'Add Tool Type',
   ADD_DELIMITER: 'Add Delimiter',
   ADD_ANOMALY_TYPE: 'Add Anomaly Type',
   EDIT_ANOMALY_TYPE: 'Edit Anomaly Type',
   EDIT_DELIMITER: 'Edit Delimiter',
   ADD_ROLE: 'Add Role',
   ADD_SOURCE: 'Add Source',
   ADD_CLUSTER: 'Add Cluster',
   ADD_INTERNAL_EXTERNAL: 'Add Internal External',
   EDIT_INTERNAL_EXTERNAL: 'Edit Internal External',
   ADD_ANOMALY_CLASSIFICATION: 'Add Anomaly Classification',
   ANOMALY_CLASSIFICATION_GUID: 'anomalyClassificationClGuid',
   ANOMALY_CLASSIFICATION_NAME: 'anomalyClassificationClName',
   EDIT_ANOMALY_CLASSIFICATION: 'Edit Anomaly Classification',
   ADD_USER: 'Add User',
   ADD_VENDOR: 'Add Vendor',
   EDIT_VENDOR: 'Edit Vendor',
   ADD_ANOMALY_CATEGORY: 'Add Anomaly Category',
   EDIT_ANOMALY_CATEGORY: 'Edit Anomaly Category',
   ADDING_NEW_DELIMITER: 'Adding the new delimiter...',
   ADMIN_TABLE: 'applicationusers_table',
   ALERT: 'Alert',
   ALL: 'All',
   ANALYSIS_DASHBOARD: 'Analysis Dashboard',
   ANOMALY_CATEGORY_NAME: 'anomalyCategoryClName',
   ANOMALY_CATEGORY_GUID: 'anomalyCategoryClGuid',
   CENOZON: 'Cenozon',
   ANOMALY_TYPE_NAME: 'anomalyTypeClName',
   ANOMALY_TYPE_GUID: 'anomalyTypeClGuid',
   ANOMALY_TYPE_DELETE_GUID: 'iliAnomalyTypeClGuid',
   CLUSTER_NAME: 'iliClusterRuleClName',
   CLUSTER_GUID: 'iliClusterRuleClGuid',
   INTERNAL_EXTERNAL_NAME: 'internalExternalClName',
   INTERNAL_EXTERNAL_GUID: 'internalExternalClGuid',
   PIPE_SEGMENT_MATERIAL_NAME: 'pipeSegmentMaterialClName',
   PIPE_SEGMENT_MATERIAL_GUID: 'pipeSegmentMaterialClGuid',
   VENDOR_LOOKUP_NAME: 'iliToolVendorClName',
   VENDOR_LOOKUP_GUID: 'iliToolVendorClGuid',
   DELIMITER_CL_NAME: 'delimiterTypeClName',
   DELIMITER_CL_GUID: 'delimiterTypeClGuid',
   DELIMITER_DELETE_GUID: 'delimiterTypeGuid',
   PIPE_LONG_SEAM_NAME: 'pipeLongSeamClName',
   PIPE_LONG_SEAM_GUID: 'pipeLongSeamClGuid',
   PIPE_GRADE_NAME: 'pipeGradeClName',
   PIPE_GRADE_GUID: 'pipeGradeClGuid',
   OPERATIONAL_AREA_LOOKUP_NAME: 'iliToolOperationalAreaClName',
   OPERATIONAL_AREA_LOOKUP_GUID: 'iliToolOperationalAreaClGuid',
   AND: 'and',
   API: 'api',
   APPLY: 'Apply',
   APPLICATION_ADMIN: 'App Admin',
   APPLICATION_JSON: 'application/json',
   APPLICATION_USER_TABLE: 'Application User table',
   APP_ADMIN: 'Application Admin',
   APP_USER_BREADCRUMBS: 'Application Administrator',
   ARCHIVED: 'ARCHIVED',
   ASC: ' asc',
   ASCENDING: 'asc',
   AUTO_COMPLETE: 'autocomplete',
   AVG_TEMP: 'Average Temperature',
   ANOMALY_REFERENCES: 'Anomaly References',
   BACK: 'Back',
   BACK_TO_LOGIN: 'Back to login',
   BEARER: 'Bearer',
   BEGIN_DATE: 'Run Date Start',
   OVERVIEW_BEGIN_DATE: 'Begin Date',
   OVERVIEW_END_DATE: 'End Date',
   BLUR: 'blur',
   CANCEL: 'Cancel',
   CLEAR_SPLIT: 'Clear',
   CENOZON_LOGO: 'Cenozon logo',
   CHANGE_PASSWORD: 'Change Password',
   CHOOSE_INSPECTION: 'Choose ILI Inspection',
   CHUNK_NAME: 'chunkName',
   CITY: 'City',
   CLIENT_ID: 'clientId',
   CLIENTSGUID: 'clientsGuid',
   CLIENT_ADMIN: 'Client Admin',
   CLIENT_MANAGEMENT: 'Client Management',
   CLIENT_NAME: 'clientname',
   CLIENT_SWITCHING: 'Switching client...',
   CLIENT_TABLE: 'clients_table',
   CLIENT_TABLE_HEADER: 'client',
   CLOSE: 'Close',
   CLUSTER_RULE_CL: 'Cluster Rule',
   COLLAPSED: 'collapsed',
   COLUMN: 'column',
   COLUMN_IN: 'column in',
   COLUMN_NAME: 'columnName',
   COLUMNS: 'columns',
   PLURAL_COLUMN: 'columns are',
   SINGULAR_COLUMN: 'column is',
   COMMENTS: 'Comments',
   COMPANY: 'Company',
   COMPANY_PROFILE: 'Company Profile',
   CONFIRMATION_TEXT: 'Confirmation',
   CONFIRMPASSWORD: 'Confirm Password',
   CONFIRM_EXISTING_VERSION: 'Are you sure want to choose existing version',
   CONFIRM_PASSWORD: 'confirmPassword',
   CONSULTANTS: 'Consultants',
   CONTINUE: 'Continue',
   CONTINUE_CLICK: 'Continue Click',
   CONTAINS_TEXT: 'CONTAINSTEXT',
   CONTAINING_TEXT_MESSAGE: 'contains some non-numeric values. Do you want to ignore (import valid values only)?',
   COUNTRY: 'Country',
   COUNTRY_GUID: 'countryGuid',
   CREATING_APP_USER: 'Creating Application User...',
   CURRENT: 'Current',
   DASHBOARD: 'Dashboard',
   DATA_OVERRIDES: 'Data Over-rides',
   DATAIMPORT: 'Data Import',
   DATA_SAVE_COMPLETED: 'Data save completed',
   DATA_ADMIN: 'Data Admin',
   DATE_FORMAT: 'MMM DD, YYYY',
   DASHBOARD_EDITOR: 'Dashboard Editor',
   DASHBOARD_VIEWER: 'Dashboard Viewer',
   DASHBOARD_DENIED: 'You are not having a permission to access overview dashboard',
   DISPLAY_DATE_FORMAT: 'MMM DD, YYYY',
   DATE_PICKER_DATE_FORMAT: 'MMM dd, yyyy',
   DEFAULT_VERSION: '1.0.0',
   DEFAULT: 'default',
   DEFAULT_DATE: '0001-01-01',
   DEFAULT_PARENTID: '00000000-0000-0000-0000-000000000000',
   DEFAULT_SPLIT_DELIMITER_UNIT: 'n/a, n/a',
   DEFAULT_EXPANDED_ROLE: '0',
   DEFORMATION_LOADER_MESSAGE: 'Loading deformation analysis dashboard...',
   DELETE: 'Delete',
   DELETE_CLIENT_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this client?',
   DELETE_CLIENT_ROLE: 'Delete Client',
   DELETE_OPERATIONALAREA_ROLE: 'Delete Area',
   DELETE_TOOL_TYPE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this tool type?',
   DELETE_OPERATIONAL_AREA_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this operational area?',
   DELETE_ROLE: 'Delete Role',
   DELETE_ANOMALY_TYPE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this anomaly type?',
   DELETE_ROLE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this role?',
   DELETE_USER_APPLICATION_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this application administrator?',
   DELETE_USER_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this user?',
   DELETE_SOURCE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this source?',
   DELETE_ANOMALY_CATEGORY_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this anomaly category?',
   DELETE_PIPE_GRADE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this pipe grade?',
   DELETE_CLUSTER_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this cluster?',
   DELETE_DELIMITER_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this delimiter?',
   DELETE_INTERNAL_EXTERNAL_CHILD_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this internal external child?',
   DELETE_PIPE_SEGMENT_MATERIAL_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this pipe segment material?',
   DELETE_PIPE_LONG_SEAM_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this pipe long seam?',
   DELETE_ANOMALY_CLASSIFICATION_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this anomaly classification?',
   DELETE_INTERNAL_EXTERNAL_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this internal external?',
   DELETE_USER_ROLE: 'Delete User',
   DELETE_VENDOR_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this vendor?',
   DELETE_VENDOR_ROLE: 'Delete Vendor',
   DELIMITER: 'Delimiter',
   DELIMITER_GUID: 'DelimiterGuid',
   DELIMITER_NAME: 'delimiterName',
   DOUBLE_SPACE: 'Double Space',
   DIFFERENTLY_MAPPED_COLUMNS: 'has been mapped differently. Please update the mapping.',
   DESC: ' desc',
   DESCRIPTION_FIELD: 'description',
   DESCRIPTION: 'Description',
   EDIT: 'Edit',
   EDIT_APPLICATION_USER: 'Edit Application Administrator',
   EDIT_TOOL_TYPE: 'Edit Tool Type',
   EDIT_CLIENT: 'Edit Client',
   EDIT_OPERATIONALAREA_ROLE: 'Edit Area',
   EDIT_OPERATIONAL_AREA: 'Edit Operational Area',
   EDIT_PROFILE: 'Edit Profile',
   EDIT_ROLE: 'Edit Role',
   EDIT_SOURCE: 'Edit Source',
   EDIT_INTERNAL_EXTERNAL_CHILD: 'Edit Internal External Child',
   EDIT_PIPE_SEGMENT_MATERIAL: 'Edit Pipe Segment Material',
   EDIT_PIPE_LONG_SEAM: 'Edit Pipe Long Seam',
   EDIT_PIPE_GRADE: 'Edit Pipe Grade',
   EDIT_CLUSTER: 'Edit Cluster',
   EDIT_USER: 'Edit User',
   EMAIL: 'Email',
   EMAIL_FIELD_DEFAULT_WIDTH: 40,
   END_DATE: 'Run Date End',
   ENTER: 'Enter',
   ENTER_ROLENAME: 'Enter role name...',
   ERROR_IN_FETCHING_QC_DASHBOARD_DATA: 'Error in fetching QC dashboard data',
   ERROR_IN_SAVING_QC_DASHBOARD_DATA: 'Error in saving QC dashboard data',
   EXCEL_COLUMN_UNIT: 'excelColumn',
   EXCEL_COLUMN: 'Listing Column',
   EXCEL_COLUMNS: 'ExcelColumns',
   EXCEL_UNIT: 'Listing Unit',
   EXCEL_UNIT_NAME: 'excelUnitName',
   EXPORT_CSV: 'Export CSV',
   EXPORT_PDF: 'Export PDF',
   FALSE: 'false',
   FETCHING_FIELD_MAPPING_DATA: 'Loading field mapping data...',
   FETCHING_FILE_NAME: 'Fetching file name...',
   FETCHING_QC_DATA: 'Fetching QC dashboard data...',
   FETCHING_SUMMARY_DETAILS: 'Fetching summary details...',
   FETCHING_VERSION_LIST: 'Searching previous version...',
   FETCHING_USER_DATA: 'Fetching User Data...',
   FEATURE_LIST_DATA: 'FEATURE_LIST_DATA',
   FEATURE_TYPE_COLUMN: 'ANOMALY_TYPE_CL',
   FEATURE_TYPE: 'Feature Type',
   FIELD_MAPPING_IGNORED: `${null}.${null}`,
   FIELD_MAPPING_VALIDATION: 'Field Mapping Validation',
   FIELD_OPERATOR_ROLES: 'Field Operators',
   FILE: 'file',
   FILE_NAME: 'fileName',
   FILE_UPLOADING: 'File is uploading...',
   FILL_IN: 'fill-in',
   FILL_MISSING: 'Fill-in where missing',
   FOLDER_OPEN: 'Folder open',
   FOLDER_CLOSE: 'Folder close',
   FOLLOWING_COLUMNS_MESSAGE: 'The following',
   FORGOT_PASSWORD_LINK_TEXT: 'Forgot Password?',
   FROM_FIELDMAPPING: 'From Field Mapping',
   FROM_LOC: 'From Loc',
   FROM_LOCATION_DESC: 'From Location',
   HI: 'Hi',
   HISTORICAL: 'Historical',
   HOME: 'Home',
   IDR_COLUMN: 'IDR',
   IDR_WARNING: 'Internal diameter restriction column hasn\'t been mapped in the following',
   IDR_WARNING_ENDING: 'Would you like to use Max Depth % for Deformation Dent features(IDR). Do you want to continue?',
   ILI_DATA_TABLE: 'ILI_DATA',
   ILI_OPERATIONAL_AREA: 'operationalArea',
   ILI_SUMMARY: 'ILISummary',
   ILI_SUMMARY_DASHBOARD: 'ILI Summary Dashboard',
   ILI_SUMMARY_SHEET_WARNING_DESCRIPTION: 'Please select at least one sheet to proceed further',
   ILI_UNIT: 'unit',
   ILI_VENDOR: 'vendor',
   ILI_VERSION: 'version',
   ILI_TOOLTYPE_GUID: 'iliToolTypeClGuid',
   INVALID: 'invalid',
   IN: 'in',
   IN_THE: 'in the',
   IMPORT_DATE: 'Import Date',
   INITIAL_VALUE: '0',
   INSPECTION_ID: 'Inspection Id',
   SEARCH_INSPECTION_ID: 'inspectionId',
   INVALID_DELIMITER_MESSAGE_COLUMN: 'column of the',
   INVALID_DELIMITER_MESSAGE_INVALID_SHEET: 'sheet is invalid.',
   INTEGRITY_ENGINEER: 'Integrity Engineer',
   IN_ACTIVE: 'InActive',
   INVITE: 'Send Invite',
   ISACTIVE: 'IsActive',
   ISO_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
   IS_LOAD_FIRST: 'isFirstLoad',
   IS_SAVE_CLICK: 'isSaveClick',
   INDEX_DESCRIPTION: 'Index Description',
   IS_ENABLE_IN_QC_INPUT: 'Enabled In QC Input',
   KEY: 'key',
   JOINT_NUMBER: 'Joint Number',
   JOINT_NUMBER_COLUMN: 'US_WELD_NUMBER',
   JOINT_FEATURE_COLUMN: 'RAW_REFERENCE_KEY',
   JOINT_NUMBER_WARNING: 'Joint number column hasn\'t been mapped in the following ',
   JOINT_NUMBER_WARNING_ENDING: 'Would you like to use feature number for joint feature number. Do you want to continue?',
   FEATURE_NUMBER_WARNING: 'Feature number column hasn\'t been mapped in the following ',
   FEATURE_NUMBER_WARNING_ENDING: 'Would you like to generate and assign unique integers as feature numbers?',
   LEFT_ANCHOR: 'left',
   LIST_OF_ROLE_NAME: 'list of role name',
   LIST_OPEN_TAG: '<li>',
   LIST_CLOSE_TAG: '</li>',
   LOADING: 'Loading ...',
   LOADING_DASHBOARD: 'Loading dashboard...',
   LOADING_OVERVIEW_DASHBOARD: 'Loading overview dashboard...',
   LOADING_PREVIEW: 'Loading preview...',
   LOADING_PROFILE: 'Loading profile...',
   LOADING_ILI_SUMMARY_DASHBOARD: 'Loading ILI summary dashboard...',
   LOADING_MENU: 'Loading menu...',
   LOADING_SUB_UNITS: 'Loading sub units...',
   LOOKUP_MANAGEMENT: 'Lookup Management',
   LOADING_SEARCH_RESULTS: 'Loading search result...',
   LOGIN: 'Login',
   LOGIN_TO_CONTINUE: 'Login To Continue',
   LOGOUT: 'Logout',
   LOGOUT_LOADER: 'Logging out...',
   LOGOUT_REQUIRED: 'Logout Required',
   LOWERCASE_LETTER_a_z: 'Atleast one lowercase letter (a - z)',
   MAIL_SENT_SUCCESS: 'Mail sent successfully!',
   MANGEMENT_ROLES: 'Management',
   MAOP_COLUMN: 'B31G_MAOP',
   MAPPING_COLUMN: 'Mapping Column',
   MASTER: 'master',
   MATCHING_PERCENTAGE: 'Match',
   MAX_OPERATING_PRESSURE: 'Maximum Operating Pressure',
   MAX_TEMP: 'Maximum Temperature',
   MAX_YIELD_STRENGTH: 'Specified Maximum Yield Strength',
   METRIC: 'Metric',
   METRIC_LOWER_CASE: 'metric',
   IMPERIAL: 'Imperial',
   IMPERIAL_LOWER_CASE: 'imperial',
   METRIC_PREF: 'imperial_metric',
   METAL_LOSS_LOADER_MESSAGE: 'Loading metal loss analysis dashboard...',
   MIN_YIELD_STRENGTH: 'Specified Minimum Yield Strength',
   MIN_TEMP: 'Minimum Temperature',
   MODEL: 'Model',
   MY_PROFILE: 'My Profile',
   NAME: 'name',
   SOURCE_FIELD_NAME: 'Name',
   NEWPASSWORD: 'newPassword',
   NEW_PASSWORD_ATTRIBUTE: 'new-password',
   NEW_PASSWORD: 'New Password',
   NO: 'No',
   NO_INDICATION: 'N',
   NO_DISTANCE_DATA: 'No Distance Data',
   NOTES: 'Notes',
   NO_DATA_FOUND: 'No data found',
   NON_APPLICABLE: 'n/a',
   NUMBERS_0_9: 'Atleast one number (0 - 9)',
   NULL: 'null',
   Next: 'Next',
   OD_COLUMN: 'OUTSIDE_DIAMETER',
   OK: 'Ok',
   OLD_PASSWORD: 'Old Password',
   OPERATIONAL_AREA: 'Operational Area',
   OPERATIONAL_AREA_EXISTS: 'Operational area already exists',
   OPERATIONAL_AREA_MANAGEMENT: 'Operational Area Management',
   OPERATIONAL_SELECT: 'Select Operational Area',
   ORIENTATION_COLUMN: 'ORIENTATION',
   OTHER_OPTION: 'Other',
   OTHER_TITLE: 'Others',
   OUTER_DIAMETER: 'Outer Diameter',
   OVERIDE: 'overide',
   OVERIDE_ALL_VALUE: 'Overide all values',
   OVERVIEW_DASHBOARD: 'Overview Dashboard',
   OVERVIEW_LOADER_MESSAGE: 'Loading overview analysis dashboard...',
   ANALYSIS_DASHBOARD_LOADER_MESSAGE: `Loading analysis dashboard data...`,
   PANEL: 'panel',
   PASSWORD: 'Password',
   PASSWORD_FIELD_MAX_WIDTH: 130,
   PASSWORD_FIELD_WIDTH: 70,
   PASSWORD_GUIDELINES: 'Password Guidelines',
   PASSWORD_INSTRUCTION: 'Password must be at least 8 characters in length',
   PASSWORD_MUST_CONT_ALL_FOLLOW: 'Password must contain all of the following:',
   PASSWORD_UPDATED: 'Password updated !',
   PASSWORD_UPDATING: 'Password updating...',
   PHONE: 'Phone',
   PHONE_NUMBER_LENGTH: 15,
   PLEASE_ENTER_OPERATIONAL_AREA: 'Please enter the operational area ',
   PLEASE_LOGOUT_MESSAGE: 'Please logout to see the client in the configuration',
   PLEASE_SELECT_VERSION_TO_OVERRIDE: 'Please select any of the following versions to override',
   PLEASE_SELECT_VALID_SOURCE: 'Please select a valid source',
   PLEASE_SELECT_VALID_ANOMALY_CATEGORY: 'Please select a valid anomaly category',
   PLEASE_TRY_AFTER_SOMETIMES: 'Please try again later',
   POSTALCODE: 'Postal Code',
   PROJECT_NAME: 'Project Name',
   PROJECT_NO: 'Project No',
   PROJECT_NUMBER: 'Project Number',
   PROVINCE_STATE: 'Province/State',
   QC_DASHBOARD_LOADER: 'QC results being generated...',
   QC_DASHBOARD_PREVIOUS_VERSION_LOADER: 'Loading previous versions QC dashboard...',
   QC_DASHBOARD_VALIDATION: 'QC Dashboard Validation',
   QUANTITY: 'Quantity',
   QUANTITY_NAME: 'quantityName',
   RATED_MAX_VELOCITY: 'Rated Max Velocity',
   RATED_MAX_WEIGHT: 'Rated Max Weight',
   REPORT_DATE: 'Report Date',
   RESEND_FORGOTPASSWORD_EMAIL: 'Resend Email',
   RESET: 'Reset',
   RESET_PASSWORD_TOKEN_EXPIRED: 'Reset password token expired !',
   RESOLUTION: 'Resolution',
   RETRY: 'Retry',
   REVERT: 'Revert',
   REVERT_QC_DASHBOARD_DATA: 'Reverting QC dashboard data...',
   REVERT_SUCCESS: 'Successfully reverted',
   ROLE: 'roles',
   ROLE_ALREADY_EXIST: 'This role name already exists',
   ROLE_GUIDE: 'rolesGuid',
   ROLE_MANAGEMENT: 'Role Management',
   ROLE_NAME: 'roleName',
   RUN_DATE_END: 'RunEndDate',
   RUN_START_DATE: 'RunStartDate',
   SAMPLING_FREQUENCY: 'Sampling Frequency',
   SAVE: 'Save',
   SAVED_DETAILS: 'SavedDetails',
   SAVING_VENDOR_OPERATIONAL_AREA: 'Saving vendor and operational area...',
   SAVE_WITHOUT_VALIDATION: 'Save without validation',
   SAVE_WITH_VALIDATION: 'Save with validation',
   SAVING: 'Saving...',
   SAVING_COMPLETED: 'Save Completed',
   SAVING_ILI_STAGING: 'Saving ILI Data staging...',
   SAVING_QC_DASHBOARD_DATA: 'Saving ILI data...',
   SAVING_TEMPLATE: 'Saving Template...',
   SAVING_TEMPLATE_ERROR: 'Error Saving template',
   SEARCHING_USER_DATA: 'Searching user data...',
   SEARCH: 'Search',
   SEARCH_DATA: 'Search Previous Versions',
   SEARCH_PROJECT_NAME: 'projectName',
   SEARCH_TOOL_TYPE: 'toolType',
   SEARCH_RUN_START_DATE: 'runStartDate',
   SEARCH_RUN_END_DATE: 'runEndDate',
   SEARCH_STATUS: 'status',
   SEARCH_OPERATIONAL_AREAS: 'Operational Areas',
   SEARCH_PROJECT_NAMES: 'Project Names',
   SEARCH_FROM_LOCATIONS: 'From Locations',
   SEARCH_TO_LOCATIONS: 'To Locations',
   SEARCH_TOOL_TYPES: 'Tool Types',
   SEAM_ORIENTATION_COLUMN: 'SEAM_ORIENTATION',
   SEAM_ORIENTATION_VALIDATION_MESSAGE: ' contains some non-numeric values. Do you want to ignore (import valid values only)?',
   SELECT: 'Select',
   SELECT_ALL: 'Select All',
   SELECT_ALL_CLIENT_DROPDOWN: 'Select all client dropdown',
   SELECT_CLIENT: 'Select Client',
   SELECT_EXCEL_FILE: 'Choose or Drag File (.csv, .xls, .xlsx, .xlsm)',
   SELECT_INSPECTION: 'Select ILI Inspection',
   SELECT_UNIT: 'Unit',
   SELECT_VERSION: 'Select Version',
   SELECTED_VENDOR_NAME: 'vendorName',
   SEND_LINK: 'Send Link',
   SENDING_MAIL: 'Sending mail...',
   SENSOR_CL: 'Sensor CL',
   SENSOR_SPACING_MAX: 'Sensor Spacing Maximum',
   SENSOR_SPACING_MIN: 'Sensor Spacing Minimum',
   SET_PASSWORD_MEDIUM_WIDTH: 108,
   SOURCE_GUID: 'sourceClGuid',
   SOURCE_NAME: 'sourceClName',
   SEAM_FACTOR: 'seamFactor',
   SMYS: 'smys',
   TOOL_TYPE_NAME: 'iliToolTypeClName',
   TOOL_TYPE_GUID: 'iliToolTypeClGuid',
   INTERNAL_EXTERNAL_CHILD_NAME: 'internalExternalChildClName',
   INTERNAL_EXTERNAL_CHILD_GUID: 'internalExternalChildClGuid',
   SHEET_TAB_PANEL: 'sheetTabPanel',
   SPACE: 'Space',
   SPLIT_FIELD: 'Split Field',
   SHEET: 'sheet',
   SHEETS: 'sheets',
   SHEET_INDEX: 'sheetIndex',
   SHEET_NAME: 'sheetName',
   SIMPLE_TAB: 'simple-tab',
   START_INDEX: 'startIndex',
   STATUS: 'Status',
   SUB_UNIT_OF_MEASURE: 'Sub Unit Of Measure',
   SUCCESS: 'Success !',
   SUMMARY_PROJECT_NO: 'projectNumber',
   SUMMARY_BEGIN_DATE: 'beginDate',
   SUMMARY_END_DATE: 'endDate',
   SUMMARY_TOOL_TYPE: 'toolTypeCl',
   SUMMARY_TOOL_VENDOR: 'toolVendorCl',
   SUMMARY_FROM_LOCATION: 'fromLocation',
   SUMMARY_TO_LOCATION: 'toLocation',
   SYMBOLS_OR_SPECIAL: 'Atleast one symbol or special character',
   SMYS_COLUMN: 'SMYS',
   TABLE_COL: 'Target Column',
   TABLE_COL_SPLIT_ONE: 'Target Column 1',
   TABLE_COL_SPLIT_TWO: 'Target Column 2',
   TABLE_COLUMN: 'tableColumn',
   TABLE_COLUMN_WARNING: 'table column',
   TABLE_UNIT: 'tableUnit',
   TABLE_UNIT_NAME: 'tableUnitName',
   TAB_PANEL: 'tabpanel',
   TARGET_UNIT: 'Target Unit',
   TEXT: 'text',
   TEXT_FIELD_VARIANT: 'outlined',
   TOGGLE_PASSWORD_VISIBILITY: 'toggle password visibility',
   TOOL_OPERATIONAL_AREA_CL: 'Operational Area',
   TOOL_TYPE: 'Tool Type',
   TOOL_TYPE_CL: 'Tool Type',
   TOOL_TYPE_WARNING: 'Tool type is not selected. Do you wish to continue?',
   TOOL_VENDOR_CL: 'Vendor',
   TO_LOC: 'To Loc',
   TO_LOCATION_DESC: 'To Location',
   TRIPLE_SPACE: 'Triple Space',
   TRUE: 'True',
   UNIT_OF_MEASURE: 'Unit Of Measure',
   UNIT_PREFERENCE: 'Unit Preference',
   UNIT_NAME: 'excelUnit',
   UNIT_TYPE: 'unitType',
   UNIT_CLEAR_WARNING: 'Please clear the excel unit in order to change the',
   UPPERCASE_LETTER_A_Z: 'Atleast one uppercase letter (A- Z)',
   URL_TOKEN: '?token=',
   URL_USER_GUID: '?userGuid=',
   URL_INDEX: 1,
   USER_ACTIVATION_TOKEN: '?userActivationToken=',
   USERNAME: 'username',
   USER_COUNT: 'user count',
   USER_GUID: 'usersGuid',
   USER_INFO_LABEL: 'Select the sheet to proceed for field mapping',
   USER_MANAGEMENT: 'User Management',
   USER_NAME: 'User Name',
   USER_NAME_ICON: 'user name icon',
   USER_TABLE: 'users_table',
   USER_TABLE_HEADER: 'user',
   VALIDATION: 'validation.',
   VENDOR_CL: 'Vendor',
   VENDOR_ID: 'VendorId',
   VENDOR_MANAGEMENT: 'Vendor Management',
   VENDOR_NAME: 'Vendor Name',
   VENDOR_SELECT: 'Select Vendor',
   VENDOR_TABLE: 'vendors_table',
   VERSION: 'Choose Version',
   VERSION_ID: 'Version Id',
   VERSION_NO: 'Version No',
   VERSION_NUMBER: 'Version Number',
   VERSION_OVERRIDE: 'Version Override',
   VERTICAL_TAB: 'vertical-tab',
   WITH: 'with',
   WITHOUT: 'without',
   WARNING: 'Warning',
   WARNING_CHANGE_CLIENT_MESSAGE: 'Are you sure you want to continue, there are unsaved changes?',
   WARNING_LENGTH_DATA: 'Would you like to correct the joint length based on start and end odometer?',
   WARNING_REVERT_SAVE: 'Clicking on revert will erase all the data. Do you still want to continue?',
   WARNING_REDIRECT_FIRST_STEP: 'After completing the save you can\'t go back to step 2 and step 3. Do you want to go to Step 1?',
   YEAR_MONTH_DAY_FORMAT: 'YYYY-MM-DD',
   YES: 'Yes',
   YES_INDICATION: 'Y',
   DECIMALFIELDVALIDATION_ERRORMESSAGE: 'is not a valid decimal number',
   DECIMALFIELDVALIDATION_INFO: '<br /><br />Possible mixed numeric values with text. Review ILI input values. Hint: If numeric values with text use the split function on the mapping page to extract the numeric values.'
};

export const formDataInput = {
   DESCENDING: 'desc',
   EXCEL_TEMPLATE: 'excelTemplateDto',
   FIELD_INPUT: 'fieldinput',
   FILE_NAME: 'fileName',
   ILI_FIELD_DETAILS: 'iliFieldDetails',
   IMPORT_DATE: 'importDate',
   INSPECTION_GUID: 'inspectionGuid',
   ILI_INSPECTION_DETAILS: 'iliInspectionDetails',
   ILI_SAVE_INPUT: 'ilisaveinput',
   IS_FIRST_LOAD: 'isFirstLoad',
   IS_JOINT_LENGTH: 'IsJointLength',
   IS_JOINT_LENGTH_OVERWRITE: 'IsJointLengthOverWrite',
   IS_LAST_JOINT_LENGTH: 'IsLastJointLength',
   IS_SAVE_CLICK: 'isSaveClick',
   IS_VALIDATED: 'isValidated',
   IS_VERSION_OVERWRITE: 'isVersionOverWrite',
   QC_INPUT: 'qcInput',
   SAVED_INSPECTION_GUID: 'savedInspectionGuid',
   REPORT_DATE: 'reportDate',
   SHEET_INDEX: 'sheetIndex',
   START_INDEX: 'startIndex',
   TEMPLATE_MASTER_GUID: 'templateMasterGuid',
   VENDOR_GUID: 'vendorGuid',
   VERSION_ID: 'versionId',
   VERSION_NOTES: 'versionNotes'
};

export const excelFormats = [
   'xlsx',
   'xls',
   'csv',
   'xlsm'
];

export const regularExpression = {
   DATA_INSIDE_DOUBLE_QUOTES: /\(([^)]+)\)/,
   EMAIL: /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
   GET_DATA_INSIDE_PARENTHESIS: /\(([^)]+)\)/,
   EXCEL_FORMATS: /\.(xlsx|xls|csv|xlsm)$/,
   PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
   PHONE: /[^0-9\+\ \/]/g,
   CITY: /[0-9]/g,
   CITY_CHECK_CHARACTER: /[^a-zA-Z ]/g,
   GET_DATA_BETWEEN_BRACKETS: /\(([^)]+)\)/,
   REMOVE_DOUBLE_QUOTES: /['"]+/g,
   REGEX_RESULT_INDEX: 1
};

export const statusCode = {
   CODE_200: 200,
   CODE_201: 201,
   CODE_204: 204,
   CODE_208: 208,
   CODE_401: 401,
   CODE_400: 400,
   CODE_GONE_ERROR: 410
};

export const clientProfileLabelText = {
   ADDRESS: 'Address',
   CITY: 'City',
   CLIENTNAME: 'Client Name',
   COUNTRY: 'Country',
   EMAIL: 'Email',
   MAINCONTACT: 'Main Contact',
   PHONE: 'Phone',
   POSTAL_CODE: 'Postal Code',
   STATE: 'Province/State'
};

export const initialPageLimit = 10;

export const initialPage = 0;

//object Label constants
export const navIcon = 'icon';
export const indeterminateLabel = 'indeterminate';
export const isActiveLabel = 'isActive';
export const clientsGuidLabel = 'clientsGuid';
export const isTabChecked = 'isTabChecked';
export const index = 'index';
export const initialSheetIndex = 0;
export const initialCharacterIndex = 0;
export const toPath = 'to';

export const defaultNode = {
   'id': 1,
   'filename': 'OPERATING AREAS',
   'children': []
};

export const eventTypes = {
   KEY_DOWN: 'keydown',
   TAB_KEY_PRESS: 'Tab',
   SHIFT_KEY_PRESS: 'Shift'
};

export const tooltipPositions = {
   TOP: 'top',
   BOTTOM: 'bottom'
};

export const apiRouter = {
   ADD_APP_ADMIN_USER: 'AddAppAdminUser',
   ADD_DELIMITER: 'AddDelimiter',
   ADD_ROLE: 'AddRoleFeatures',
   ADD_IMPERIAL_USER_PREFERENCE: 'AddImperialUserPreference',
   ADDUSER: 'AddUser',
   ANOMALY_CLASSIFICATION: 'AnomalyClassificationCl',
   ANALYSIS_DASHBOARD_URL: '/analysis-dashboard',
   AUTHENICATE: 'authenticate',
   CASCADE_DATA_SAVE: 'CascadeAndSaveIliData',
   CHANGEPASSWORD: 'ChangePassword',
   CHECK_APP_USEREMAIL_EXISTS: 'CheckAppAdminEmailExists',
   CHECK_CLIENT_NAME_EXISTS: 'CheckClientName',
   CHECK_ROLE_NAME_EXISTS: 'CheckRoleNameExist',
   CHECK_PASSWORD: 'CheckPassword',
   CHECK_USEREMAIL_EXISTS: 'CheckUserEmailExists',
   CHECK_SOURCE_EXISTS: 'CheckSourceClExists',
   CHECK_ANOMALY_TYPE_EXISTS: 'CheckAnomalyTypeClExists',
   CHECK_PIPE_GRADE_EXISTS: 'CheckPipeGradeClNameExists',
   CHECK_CLUSTER_RULE_EXISTS: 'CheckIliClusterRuleClNameExists',
   CHECK_PIPE_LONG_SEAM_EXISTS: 'CheckPipeLongSeamClNameExists',
   CHECK_TOOL_TYPE_EXISTS: 'CheckIliToolTypeClNameExists',
   CHECK_DELIMITER_EXISTS: 'CheckDelimiterTypeClNameExists',
   CHECK_ANOMALY_CLASSIFICATION_EXISTS: 'CheckAnomalyClassificationClExists',
   CHECK_ANOMALY_CATEGORY_EXISTS: 'CheckAnomalyCategoryClExists',
   CHECK_INTERNAL_EXTERNAL_EXISTS: 'CheckInternalExternalClNameExists',
   CHECK_INTERNAL_EXTERNAL_CHILD_EXISTS: 'CheckInternalExternalChildClNameExists',
   CHECK_PIPE_SEGEMENT_MATERIAL_EXISTS: 'CheckPipeSegmentMaterialClNameExists',
   CHECK_VENDOR_NAME_EXISTS: 'CheckVendorExists',
   CHECK_OPERATIONAL_AREA_LOOKUP_EXISTS: 'CheckIliToolOperationalAreaClNameExists',
   CHECK_LOOKUP_VENDOR_NAME_EXISTS: 'CheckIliToolVendorClExists',
   CHUNK_NUMBER: 'chunkNumber',
   CLIENT: 'Clients',
   CLIENT_GUID: 'clientGuid',
   CLIENT_PROFILE: 'GetClient',
   CLIENT_URL_ROLES: '/client/',
   COMMON: 'Common',
   COMMON_USER_PREFERENCE: 'GetUserPreferences',
   COUNTRY: 'Country',
   DASHBOARD: 'Dashboard',
   GET_FILE_NAME: 'GetFileName',
   DATA_IMPORT: '/data-import',
   DATA_SUMMARY: 'DataSummary',
   DELETE_ROLE: 'DeleteRoleFeatures',
   DELETE_ORIGINAL_FILE: 'deleteOriginalFile',
   EMAIL: 'email',
   EXCEL_IMPORT: 'ExcelDataImport',
   FEATURES: 'Features',
   FIELD_MAPPING: 'FieldMapping',
   FIELD_VALIDATION: 'FieldValidation',
   FILE_NAME: 'fileName',
   FILE_UPLOAD: 'FileUploader',
   FORGOT_PASSWORD: 'ForgotPassword',
   GET_ALL_FEATURES: 'GetAllFeatures',
   GET_ALL_UNITS: 'GetAllUnits',
   GET_ANALYSIS_DASHBOARD_DATA: 'GetAnalysisDashboardOverViewData',
   GET_ANOMALY_CATEGORY: 'GetAnomalyTypes',
   GET_ANALYSIS_DASHBOARD_URL: 'GetDashboardUrl',
   GET_ANALYSIS_DASHBOARD_VIEW: 'GetAnalysisDashboardView',
   GET_ANALYSIS_DASHBOARD_METAL_LOSS: 'GetAnalysisDashboardMetalLossData',
   GET_ANALYSIS_DASHBOARD_DEFORMATION: 'GetAnalysisDashboardDeformationData',
   GET_ANALYSIS_DASHBOARD_EXPORT_DATA: 'GetAnalysisDashboardExportData',
   GET_APP_ADMIN_USERS: 'getappadminusers',
   GET_DATA_SUMMARY_DASHBOARD: 'GetDataSummaryDashboard',
   GET_DELIMITER: 'GetDelimiter',
   GET_FIELD_MATCHING: 'GetFieldMatching',
   GET_OPERATIONAL_AREA: 'OperationalArea',
   GET_OPERATIONAL_AREA_LIST: 'GetOperationalAreas',
   GET_QUALITY_CONTROL_DASHBOARD: 'GetQualityControlDashboardData',
   GET_QUANTITY: 'GetUnitsByQuantity',
   GET_ROLE_BY_ID: 'GetRoleFeaturesById',
   GET_ROLE_FEATURES: 'GetRoleFeatures',
   GET_SOURCE_NAMES: 'GetSourceClNames',
   GET_ANOMALY_CLASSIFICATION_NAMES: 'GetAnomalyClassificationClNames',
   GET_INTERNAL_EXTERNAL_NAMES: 'GetInternalExternalClNames',
   GET_ANOMALY_CATEGORY_NAMES: 'GetAnomalyCategoryClNames',
   GET_STATEBY_COUNTRY: 'GetStatesByCountry',
   GET_SUMMARY_LIST: 'GetILISummaryFields',
   GET_TABLE_UNITS: 'Gettableunits',
   GET_USER: 'getuser',
   GET_USER_DETAIL: 'getuserdetail',
   GET_USER_PROFILE: 'GetUserProfile',
   GET_VENDORS: 'GetVendors',
   OPERATIONAL_AREA_LOOKUP: 'IliToolOperationalAreaCl',
   ID: 'id',
   ILI_DATA_SAVE: 'IliInspection',
   ILI_VERSIONING: 'ILIVersionSearch',
   IMPORT_EXCEL_DATA: 'ImportExcelDetails',
   INSERT_NEW_TEMPLATE: 'InsertNewTemplate',
   IS_ACTIVE: 'isActive',
   IS_ASC: 'isAsc',
   LOGIN: '/login',
   LOOKUP_MANAGEMENT: '/lookup-management',
   MENU: 'menu',
   MY_PROFILE: '/my-profile',
   OPERATIONAL_AREA_GUID: 'operationalAreaGuid',
   OPERATIONAL_AREA_URL_FOR_ROLES: '/operational-area',
   ORDER_BY: 'orderBy',
   PAGE_NUMBER: 'pageNumber',
   PAGE_SIZE: 'pageSize',
   QUALITY_CONTROL_DASHBOARD: 'QualityControlDashboard',
   GET_CONVERTED_VALUE: 'GetUnitConversion',
   GET_QC_DATA: 'GetQcLazyLoadedData',
   GET_SEARCH_FIELD_RESULTS: '/GetSearchFieldsResults',
   GET_SEARCH_RESULTS: '/GetSearchResults',
   REVERT_ILI_DATA: 'RevertIliData',
   REFRESH_TOKEN: 'RefreshToken',
   RESET_PASSWORD: 'ResetPassword',
   REVOKE_TOKEN: 'RevokeToken',
   ROLE: 'Roles',
   ROLE_UPDATE: 'UpdateRoleFeatures',
   ROLE_URL: 'role-management',
   ROLES: 'roles',
   ROLLBACK_DATA: 'RollbackIliStagingData',
   RUN_LIST: 'GetILIRunList',
   SAVE_ILI_STAGING: 'SaveIliDataStaging',
   SAVE_VENDOR_OPERATIONAL: 'InsertVendorOperationalDetails',
   OVERVIEW_DASHBOARD: 'OverviewDashboard',
   GET_OVERVIEW_FILTERS: 'GetOverviewDashboardFilterResults',
   GET_OVERVIEW_RESULTS: 'GetOverviewDashboardResults',
   SEARCH: 'search',
   SEARCH_TEXT: 'searchText',
   SEARCH_URL: '/search',
   SENDEMAIL: 'ResetPasswordSendEmail',
   SOURCE: 'SourceCl',
   INTERNAL_EXTERNAL_CHILD: 'InternalExternalChildCl',
   ANOMALY_TYPE: 'AnomalyTypeCl',
   PIPE_SEGMENT_MATERIAL: 'PipeSegmentMaterialCl',
   PIPE_LONG_SEAM: 'PipeLongSeamCl',
   PIPE_GRADE: 'PipeGradeCl',
   CLUSTER: 'IliClusterRuleCl',
   TOOL_TYPE: 'IliToolTypeCl',
   INTERNAL_EXTERNAL: 'InternalExternalCl',
   ANOMALY_CATEGORY: 'AnomalyCategoryCl',
   DELIMITER: 'IliDelimiterTypeCl',
   STATE: 'State',
   SUCCESS_UPDATE: '/success-update',
   TEMPLATE_SAVE: 'TemplateSave',
   UNIT: 'GetUnitQuantities',
   UPDATE_PASSWORD: 'UpdatePassword',
   UPDATE_USER_PROFILE: 'UpdateUserProfile',
   UPLOAD_CANCEL: 'DeleteFile',
   UPLOAD_CHUNK: 'UploadChunk',
   UPLOAD_COMPLETE: 'UploadComplete',
   USER_ACTIVATION_TOKEN: 'SendUserActivationEmail',
   USER_MANAGEMENT_URL_FOR_ROLES: '/user-management',
   USERS_GUID: 'usersGuid',
   USERS: 'users',
   VENDORS: 'Vendors',
   VENDOR_LOOKUP: 'IliToolVendorCl',
   VENDOR_GUID: 'vendorsGuid',
   VENDOR_NAME: 'vendorname'
};

export const sessionStorageKey = {
   ADMIN_PREF: 'admin_pref',
   CLIENT_DETAILS: 'client-details',
   CLIENT_PREF: 'client_pref',
   SELECTED_CLIENT: 'selected_client',
   METRIC_PREF: 'imperial_metric',
   TOKEN: 'token',
   USER_CURRENT_CLIENT_DETAILS: 'user-currentclient-details',
   USER_DETAILS: 'user-details',
   USER_PREF: 'user_pref',
   USER_ROLES: 'user-roles',
   VENDOR_PREF: 'vendor_pref'
};

export const dataTypeCheck = {
   OBJECT: 'object',
   STRING: 'string'
};

export const stringConstant = {
   COMMA_OPERATOR: ',',
   DECREMENT_OPERATOR: '-',
   DOT_OPERATOR: '.',
   EMPTY_STRING: '',
   INCREMENT_OPERATOR: '+',
   PARENTHESIS_OPEN: '(',
   PARENTHESIS_CLOSE: ')',
   PIPE_OPERATOR: '|',
   PERCENTAGE: '%',
   ASSIGNTO_OPERATOR: '=',
   DIVISION_OPERATOR: '/',
   SINGLE_SPACE_STRING: ' ',
   UNDERSCORE_OPERATOR: '_',
   SEMICOLON_OPERATOR: ';'
};

export const renderConstant = {
   EMPTY: ''
};

export const graphBackgroundColors = [
   '#36a2eb',
   '#4bc0c0',
   '#ff6384',
   '#ffce56',
   '#9966ff',
   '#ff9f40'
];

export const routerConstants = {
   LOGIN: 'Login Page',
   FORGOT_PASSWORD_URL: '/forgot-password',
   FORGOT_PASSWORD: 'Forgot Password',
   SET_PASSWORD_URL: '/set-password',
   SET_PASSWORD: 'Set Password',
   SUCCESS_UPDATE_URL: '/success-update',
   SUCCESS_UPDATE: 'Success Set Password',
   SUCCESS_MAIL_SEND_URL: '/success-mailsend',
   SUCCESS_MAIL_SEND: 'Success Mail Send',
   LOOKUP_MANAGEMENT: '/lookup-management',
   MY_PROFILE_URL: 'my-profile',
   CLIENT_MANAGEMENT_URL: '/client-management',
   ROLE_MANAGEMENT_URL: '/role-management',
   VENDOR_MANAGEMENT_URL: '/vendor-management',
   APPLICATION_ADMIN_URL: '/application-administrator',
   COMPANY_PROFILE_URL: '/company-profile',
   DASHBOARD_URL: '/overview-dashboard',
   ANALYSIS_DASHBOARD_URL: '/analysis-dashboard',
   SEARCH: '/search'
};

const oneMbChunkSize = 1048576;

const defaultChunkSizeSupported = 3;

//its 3MB, increase the number measure in mb
export const defaultChunkSize = oneMbChunkSize * defaultChunkSizeSupported;

export const tokenValidity = {
   convertToMilliSeconds: 1000,
   oneMinuteInMilliSeconds: 60000,
   fourMinutesInMilliSeconds: 240000,
   checkUserDetailsInterval: 10000
};

export const loaderMessages = [
   'Data being validated...',
   'Data importing...'
];

export const fieldValidationConstants = {
   IDR_MISMATCH: 'IDRMISMATCH',
   CONTAINS_TEXT: 'CONTAINSTEXT',
   VALIDATION_ERROR_DETAILS: 'VALIDATIONERRORDETAILS',
   INVALID_DELIMITER: 'INVALIDDELIMITER'
};

export const searchIconName = 'cilFilter';
