/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
export const clientProfileGrid = {
   DefaultSpacing: 3
};

export const myProfileGrid = {
   DefaultSpacing: 3
};

export const roleManagementGrid = {
   DefaultSpacing: 2
};

export const operationalAreaGrid = {
   DefaultSpacing: 2,
   MaximumLength: 12,
   TextFieldLength: 10
};

export const dataImportGrid = {
   DefaultSpacing: 2,
   DataEntryColumn: 3,
   LargeDataEntryColumn: 4,
   CommentsColumn: 9,
   QcSpacing: 1,
   GenericRowHeader: 1,
   HeaderPixel: 34,
   HeaderBuffer: 30,
   DefaultHeight: 400
};

export const gridWidth = {
   ExcelColumnWidth: 2,
   ChartWidth: 50,
   ColumnWidth: 3,
   CustomWidth: 8,
   CustomMaxWidth: 10,
   DefaultMinWidth: 2,
   DefaultWidth: 6,
   InitialWidth: 0,
   MaxWidth: 12,
   MinWidth: 3,
   UserManagementMd: 8,
   UnitsWidth: 2,
   ChartLgWidth: 11,
   UnitImageWidth: 0.5,
   QcSaveGridWidth: 4,
   SwitchWidth: 4,
   SwitchSmWidth: 7,
   GraphDefaultWidth: 10,
   SmWidth: 1,
   ClientNameWidth: 9
};

export const gridPadding = {
   DefaultPadding: 3
};

const baseGridDefaults = {
   DefaultSpacing: 3,
   ModalSpacing: 2,
   PaginationIncrement: 1,
   NoDataFoundColumnSpan: 10,
   NoDataFoundAlignment: 'center'
};

export const initialDataCount = Object.freeze(0);

export const applicationAdmin = Object.freeze(baseGridDefaults);

export const vendor = Object.freeze(baseGridDefaults);

export const user = Object.freeze(baseGridDefaults);

export const client = Object.freeze(baseGridDefaults);
