/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { snackBarReducerConstant } from '../reducerConstant';
import { displayText } from '../../constant';
import { isFailureSnackbarWarningEmpty } from '../../components/shared/helper';

export const showSuccessSnackbar = (message = displayText.SUCCESS) => {
   return dispatch => {
      dispatch({ type: snackBarReducerConstant.SNACKBAR_SUCCESS, message });
   };
};

export const showFailureSnackbar = message => {
   const messageData = isFailureSnackbarWarningEmpty(message);
   return dispatch => {
      dispatch({ type: snackBarReducerConstant.SNACKBAR_FAILURE, message: messageData });
   };
};

export const showWarningSnackbar = message => {
   return dispatch => {
      dispatch({ type: snackBarReducerConstant.SNACKBAR_WARNING, message });
   };
};

export const clearSnackbar = () => {
   return dispatch => {
      dispatch({ type: snackBarReducerConstant.SNACKBAR_CLEAR });
   };
};
