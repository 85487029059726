/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { decryptData } from '../../../components/shared/helper';
import { apiRouter } from '../../../constant';
import { lookupManagementConstant } from '../../reducerConstant';

const initialState = {
   globalLookupLoader: false,

   sourceDetails: null,
   isSourceAdded: null,
   isSourceEdited: null,
   isSourceDeleted: null,
   sourcetableLoader: false,
   allSources: null,

   anomalyClassificationDetails: null,
   isAnomalyClassificationAdded: null,
   isAnomalyClassificationEdited: null,
   isAnomalyClassificationDeleted: null,
   anomalyClassificationTableLoader: null,
   allAnomalyClassification: null,

   internalExternalChildDetails: null,
   isInternalExternalChildAdded: null,
   isInternalExternalChildEdited: null,
   isInternalExternalChildDeleted: null,
   internalExternalChildTableLoader: null,

   anomalyCategoryDetails: null,
   isAnomalyCategoryAdded: null,
   isAnomalyCategoryEdited: null,
   isAnomalyCategoryDeleted: null,
   anomalyCategoryTableLoader: null,
   allAnomalyCategory: null,

   clusterDetails: null,
   isClusterAdded: null,
   isClusterEdited: null,
   isClusterDeleted: null,
   clusterTableLoader: null,

   toolTypeDetails: null,
   isToolTypeAdded: null,
   isToolTypeEdited: null,
   isToolTypeDeleted: null,
   toolTypeTableLoader: null,

   delimiterDetails: null,
   isDelimiterAdded: null,
   isDelimiterEdited: null,
   isDelimiterDeleted: null,
   delimiterTableLoader: null,

   anomalyTypeDetails: null,
   isAnomalyTypeAdded: null,
   isAnomalyTypeEdited: null,
   isAnomalyTypeDeleted: null,
   anomalyTypeTableLoader: null,

   pipeSegmentMaterialDetails: null,
   isPipeSegmentMaterialAdded: null,
   isPipeSegmentMaterialEdited: null,
   isPipeSegmentMaterialDeleted: null,
   pipeSegmentMaterialTableLoader: null,

   pipeLongSeamDetails: null,
   isPipeLongSeamAdded: null,
   isPipeLongSeamEdited: null,
   isPipeLongSeamDeleted: null,
   pipeLongSeamTableLoader: null,

   pipeGradeDetails: null,
   isPipeGradeAdded: null,
   isPipeGradeEdited: null,
   isPipeGradeDeleted: null,
   pipeGradeTableLoader: null,

   operationalAreaDetails: null,
   isOperationalAreaAdded: null,
   isOperationalAreaEdited: null,
   isOperationalAreaDeleted: null,
   operationalAreaTableLoader: null,

   vendorDetails: null,
   isVendorAdded: null,
   isVendorEdited: null,
   isVendorDeleted: null,
   vendorTableLoader: null
};

const lookupManageReducer = (state = initialState, action = {}) => {
   const { lookupController, type } = action;
   switch (true) {
      //Lookup
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.SOURCE)):
         return {
            ...state,
            sourceDetails: action.value.data,
            isSourceAdded: null,
            isSourceEdited: null,
            isSourceDeleted: null,
            sourcetableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.ANOMALY_CLASSIFICATION)):
         return {
            ...state,
            anomalyClassificationDetails: action.value.data,
            isAnomalyClassificationAdded: null,
            isAnomalyClassificationEdited: null,
            isAnomalyClassificationDeleted: null,
            anomalyClassificationTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.ANOMALY_CATEGORY)):
         return {
            ...state,
            anomalyCategoryDetails: action.value,
            isAnomalyCategoryAdded: null,
            isAnomalyCategoryEdited: null,
            isAnomalyCategoryDeleted: null,
            anomalyCategoryTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.ANOMALY_TYPE)):
         return {
            ...state,
            anomalyTypeDetails: action.value.data,
            isAnomalyTypeAdded: null,
            isAnomalyTypeEdited: null,
            isAnomalyTypeDeleted: null,
            anomalyTypeTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.CLUSTER)):
         return {
            ...state,
            clusterDetails: action.value.data,
            isClusterAdded: null,
            isClusterEdited: null,
            isClusterDeleted: null,
            clusterTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL)):
         return {
            ...state,
            internalExternalDetails: action.value.data,
            isInternalExternalAdded: null,
            isInternalExternalEdited: null,
            isInternalExternalDeleted: null,
            internalExternalTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL_CHILD)):
         return {
            ...state,
            internalExternalChildDetails: action.value.data,
            isInternalExternalChildAdded: null,
            isInternalExternalChildEdited: null,
            isInternalExternalChildDeleted: null,
            internalExternalChildTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.DELIMITER)):
         return {
            ...state,
            delimiterDetails: action.value.data,
            isDelimiterAdded: null,
            isDelimiterEdited: null,
            isDelimiterDeleted: null,
            delimiterTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.PIPE_GRADE)):
         return {
            ...state,
            pipeGradeDetails: action.value.data,
            isPipeGradeAdded: null,
            isPipeGradeEdited: null,
            isPipeGradeDeleted: null,
            pipeGradeTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.PIPE_LONG_SEAM)):
         return {
            ...state,
            pipeLongSeamDetails: action.value.data,
            isPipeLongSeamAdded: null,
            isPipeLongSeamEdited: null,
            isPipeLongSeamDeleted: null,
            pipeLongSeamTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.PIPE_SEGMENT_MATERIAL)):
         return {
            ...state,
            pipeSegmentMaterialDetails: action.value.data,
            isPipeSegmentMaterialAdded: null,
            isPipeSegmentMaterialEdited: null,
            isPipeSegmentMaterialDeleted: null,
            pipeSegmentMaterialTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.OPERATIONAL_AREA_LOOKUP)):
         return {
            ...state,
            operationalAreaDetails: action.value.data,
            isOperationalAreaAdded: null,
            isOperationalAreaEdited: null,
            isOperationalAreaDeleted: null,
            pipeGradeTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.TOOL_TYPE)):
         return {
            ...state,
            toolTypeDetails: action.value.data,
            isToolTypeAdded: null,
            isToolTypeEdited: null,
            isToolTypeDeleted: null,
            toolTypeTableLoader: false
         };
      case ((lookupManagementConstant.FETCH_LOOKUP_DETAILS === type) && (lookupController === apiRouter.VENDOR_LOOKUP)):
         return {
            ...state,
            vendorDetails: action.value.data,
            isVendorAdded: null,
            isVendorEdited: null,
            isVendorDeleted: null,
            vendorTableLoader: false
         };

      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.SOURCE)):
         return {
            ...state,
            sourcetableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.ANOMALY_CLASSIFICATION)):
         return {
            ...state,
            anomalyClassificationTableLoader: action.value
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.ANOMALY_CATEGORY)):
         return {
            ...state,
            anomalyCategoryTableLoader: action.value
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.ANOMALY_TYPE)):
         return {
            ...state,
            anomalyTypeTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.CLUSTER)):
         return {
            ...state,
            clusterTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL)):
         return {
            ...state,
            internalExternalTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL_CHILD)):
         return {
            ...state,
            internalExternalChildTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.DELIMITER)):
         return {
            ...state,
            delimiterTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.PIPE_GRADE)):
         return {
            ...state,
            pipeGradeTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.PIPE_LONG_SEAM)):
         return {
            ...state,
            pipeLongSeamTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.PIPE_SEGMENT_MATERIAL)):
         return {
            ...state,
            pipeSegmentMaterialTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.OPERATIONAL_AREA_LOOKUP)):
         return {
            ...state,
            operationalAreaTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.TOOL_TYPE)):
         return {
            ...state,
            toolTypeTableLoader: action.value,
         };
      case ((lookupManagementConstant.LOOKUP_TABLE_LOADER === type) && (lookupController === apiRouter.VENDOR_LOOKUP)):
         return {
            ...state,
            vendorTableLoader: action.value,
         };

      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.SOURCE)):
         return {
            ...state,
            isSourceAdded: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.ANOMALY_CLASSIFICATION)):
         return {
            ...state,
            isAnomalyClassificationAdded: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.ANOMALY_CATEGORY)):
         return {
            ...state,
            isAnomalyCategoryAdded: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.ANOMALY_TYPE)):
         return {
            ...state,
            isAnomalyTypeAdded: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.CLUSTER)):
         return {
            ...state,
            isClusterAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL)):
         return {
            ...state,
            isInternalExternalAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL_CHILD)):
         return {
            ...state,
            isInternalExternalChildAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.DELIMITER)):
         return {
            ...state,
            isDelimiterAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.PIPE_GRADE)):
         return {
            ...state,
            isPipeGradeAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.PIPE_LONG_SEAM)):
         return {
            ...state,
            isPipeLongSeamAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.PIPE_SEGMENT_MATERIAL)):
         return {
            ...state,
            isPipeSegmentMaterialAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.OPERATIONAL_AREA_LOOKUP)):
         return {
            ...state,
            isOperationalAreaAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.TOOL_TYPE)):
         return {
            ...state,
            isToolTypeAdded: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_ADDED === type) && (lookupController === apiRouter.VENDOR_LOOKUP)):
         return {
            ...state,
            isVendorAdded: action.value,
         };

      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.SOURCE)):
         return {
            ...state,
            isSourceEdited: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.ANOMALY_CLASSIFICATION)):
         return {
            ...state,
            isAnomalyClassificationEdited: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.ANOMALY_CATEGORY)):
         return {
            ...state,
            isAnomalyCategoryEdited: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.ANOMALY_TYPE)):
         return {
            ...state,
            isAnomalyTypeEdited: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.CLUSTER)):
         return {
            ...state,
            isClusterEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL)):
         return {
            ...state,
            isInternalExternalEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL_CHILD)):
         return {
            ...state,
            isInternalExternalChildEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.DELIMITER)):
         return {
            ...state,
            isDelimiterEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.PIPE_GRADE)):
         return {
            ...state,
            isPipeGradeEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.PIPE_LONG_SEAM)):
         return {
            ...state,
            isPipeLongSeamEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.PIPE_SEGMENT_MATERIAL)):
         return {
            ...state,
            isPipeSegmentMaterialEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.OPERATIONAL_AREA_LOOKUP)):
         return {
            ...state,
            isOperationalAreaEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.TOOL_TYPE)):
         return {
            ...state,
            isToolTypeEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_UPDATED === type) && (lookupController === apiRouter.VENDOR_LOOKUP)):
         return {
            ...state,
            isVendorEdited: action.value,
         };

      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.SOURCE)):
         return {
            ...state,
            isSourceDeleted: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.ANOMALY_CLASSIFICATION)):
         return {
            ...state,
            isAnomalyClassificationEdited: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.ANOMALY_CATEGORY)):
         return {
            ...state,
            isAnomalyCategoryDeleted: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.ANOMALY_TYPE)):
         return {
            ...state,
            isAnomalyTypeDeleted: action.value
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.CLUSTER)):
         return {
            ...state,
            isClusterEdited: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL)):
         return {
            ...state,
            isInternalExternalDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.INTERNAL_EXTERNAL_CHILD)):
         return {
            ...state,
            isInternalExternalChildDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.DELIMITER)):
         return {
            ...state,
            isDelimiterDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.PIPE_GRADE)):
         return {
            ...state,
            isPipeGradeDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.PIPE_LONG_SEAM)):
         return {
            ...state,
            isPipeLongSeamDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.PIPE_SEGMENT_MATERIAL)):
         return {
            ...state,
            isPipeSegmentMaterialDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.OPERATIONAL_AREA_LOOKUP)):
         return {
            ...state,
            isOperationalAreaDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.TOOL_TYPE)):
         return {
            ...state,
            isToolTypeDeleted: action.value,
         };
      case ((lookupManagementConstant.IS_LOOKUP_DELETED === type) && (lookupController === apiRouter.VENDOR_LOOKUP)):
         return {
            ...state,
            isVendorDeleted: action.value,
         };
      case (lookupManagementConstant.GLOBAL_LOOKUP_LOADER === type):
         return {
            ...state,
            globalLookupLoader: action.value
         };
      case (lookupManagementConstant.GET_ALL_SOURCES === type):
         return {
            ...state,
            allSources: action.value
         };
      case (lookupManagementConstant.GET_ALL_ANOMALY_CLASSIFICATION === type):
         return {
            ...state,
            allAnomalyClassification: action.value
         };
      case (lookupManagementConstant.GET_ALL_ANOMALY_CATEGORY === type):
         return {
            ...state,
            allAnomalyCategory: action.value
         };
      case (lookupManagementConstant.GET_ALL_INTERNAL_EXTERNAL === type):
         return {
            ...state,
            allInternalExternal: action.value
         };
      default:
         return state;
   }
};

export default lookupManageReducer;
