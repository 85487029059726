/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { combineReducers } from 'redux';
import vendorManageReducer from './vendorManageReducer';
import userReducer from './userReducer';
import userManageReducer from './userManageReducer';
import applicationUserManageReducer from './applicationUserManageReducer';
import snackbarReducer from './snackbarReducer';
import clientManageReducer from './clientManageReducer';
import roleManageReducer from './roleManageReducer';
import operationalAreaManageReducer from './operationalAreaReducer';
import dataImportManageReducer from './dataImportManageReducer';
import sideBarReducer from './sideBarReducer';
import dashboardReducer from './dashboardReducer';
import searchReducer from './searchReducer';
import overviewDashboardReducer from './overviewDashboardReducer';
import lookupManageReducer from './lookupManageReducer';

const rootReducer = combineReducers({
   vendorManage: vendorManageReducer,
   user: userReducer,
   userManage: userManageReducer,
   snackbar: snackbarReducer,
   clientManage: clientManageReducer,
   appUserManage: applicationUserManageReducer,
   roleManage: roleManageReducer,
   opsAreaManage: operationalAreaManageReducer,
   dataImportManage: dataImportManageReducer,
   sideBar: sideBarReducer,
   dashboard: dashboardReducer,
   search: searchReducer,
   overviewDashboard: overviewDashboardReducer,
   lookupManage: lookupManageReducer
});

export default rootReducer;
