/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
export const vendorManageReducerConstant = {
   FETCH_COUNTRY_LIST: 'FETCH_COUNTRY_LIST',
   FETCH_STATE_LIST: 'FETCH_STATE_LIST',
   FETCH_VENDOR_CLEAR: 'FETCH_VENDOR_CLEAR',
   FETCH_VENDORS: 'FETCH_VENDORS',
   FETCH_VENDORS_CLEAR: 'FETCH_VENDORS_CLEAR',
   IS_VENDOR_DELETE_SUCCESS: 'IS_VENDOR_DELETE_SUCCESS',
   IS_VENDOR_EDIT_SUCCESS: 'IS_VENDOR_EDIT_SUCCESS',
   IS_VENDOR_SUCCESS: 'IS_VENDOR_SUCCESS',
   VENDOR_ADD_CLEAR: 'VENDOR_ADD_CLEAR',
   VENDOR_DELETE_CLEAR: 'VENDOR_DELETE_CLEAR',
   VENDOR_EDIT_CLEAR: 'VENDOR_EDIT_CLEAR',
   VENDOR_LOADER: 'VENDOR_LOADER',
   VENDORTABLE_LOADER: 'VENDORTABLE_LOADER'
};

export const operationalAreaReducerConstant = {
   ACTION: 'ACTION',
   ADD_OPERATIONALAREA_CLEAR: 'ADD_OPERATIONALAREA_CLEAR',
   ADD_OPERATIONALAREA_SUCCESS: 'ADD_OPERATIONALAREA_SUCCESS',
   DELETE_OPERATIONALAREA_CLEAR: 'DELETE_OPERATIONALAREA_CLEAR',
   DELETE_OPERATIONALAREA_SUCCESS: 'DELETE_OPERATIONALAREA_SUCCESS',
   EDIT_OPERATIONALAREA_CLEAR: 'EDIT_OPERATIONALAREA_CLEAR',
   EDIT_OPERATIONALAREA_SUCCESS: 'EDIT_OPERATIONALAREA_SUCCESS',
   FETCH_OPERATIONAL_AREA: 'FETCH_OPERATIONAL_AREA',
   FETCH_OPERATIONAL_AREA_RELOAD: 'FETCH_OPERATIONAL_AREA_RELOAD',
   OPSAREA_LOADER: 'OPSAREA_LOADER'
};

export const userReducerConstant = {
   CLEAR_FORGET_PASSWORD: 'CLEAR_FORGET_PASSWORD',
   CLIENT_SELECT_LOADER: 'CLIENT_SELECT_LOADER',
   COMMON_USER_PREFERENCE: 'Common/GetUserPreferences',
   FETCH_MY_PROFILE: 'FETCH_MY_PROFILE',
   FETCH_USER_MENU: 'FETCH_USER_MENU',
   LOADER_SHOW: 'LOADER_SHOW',
   LODER_HIDE: 'LODER_HIDE',
   LOGIN_USER: 'LOGIN_USER',
   MY_PROFILE_LOADER: 'MY_PROFILE_LOADER',
   RESET_TOKEN_EXPIRED: 'RESET_TOKEN_EXPIRED',
   REMOVE_REFRESH_LOADER: 'REMOVE_REFRESH_LOADER',
   REMOVE_LOGOUT_LOADER: 'REMOVE_LOGOUT_LOADER',
   SET_ERR_MSG: 'SET_ERR_MSG',
   SET_FEATURES_LOADER: 'SET_FEATURES_LOADER',
   SET_PASSWORD_LODER_HIDE: 'SET_PASSWORD_LODER_HIDE',
   SET_PASSWORD_LODER_SHOW: 'SET_PASSWORD_LODER_SHOW',
   SET_REFRESH_LOADER: 'SET_REFRESH_LOADER',
   SET_UPDATE_MY_PROFILE: 'SET_UPDATE_MY_PROFILE',
   SET_LOGOUT_LOADER: 'SET_LOGOUT_LOADER',
   TOGGLE_LOADER: 'TOGGLE_LOADER',
   UPDATE_MY_PROFILE: 'UPDATE_MY_PROFILE',
   UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
   UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
   UPDATE_USERNAME: 'UPDATE_USERNAME',
   USER_CLIENT_INFO: 'USER_CLIENT_INFO',
   USER_SENDLINK_FAILURE: 'USER_SENDLINK_FAILURE',
   USER_SENDLINK_SUCCESS: 'USER_SENDLINK_SUCCESS',
   VALIDATION_MESSAGE: 'VALIDATION_MESSAGE'
};

export const sideBarReducerConstant = {
   UPDATE_SIDE_BAR_STATE: 'UPDATE_SIDE_BAR_STATE'
};

export const snackBarReducerConstant = {
   SNACKBAR_CLEAR: 'SNACKBAR_CLEAR',
   SNACKBAR_FAILURE: 'SNACKBAR_FAILURE',
   SNACKBAR_SUCCESS: 'SNACKBAR_SUCCESS',
   SNACKBAR_WARNING: 'SNACKBAR_WARNING'
};

export const applicationUserManageReducerConstant = {
   APP_USER_ADD_CLEAR: 'APP_USER_ADD_CLEAR',
   APP_USER_CREATE_LOADER: 'APP_USER_CREATE_LOADER',
   APP_USER_DELETE_CLEAR: 'APP_USER_DELETE_CLEAR',
   APP_USER_EDIT_CLEAR: 'APP_USER_EDIT_CLEAR',
   APP_USER_LOADER: 'APP_USER_LOADER',
   APP_USER_LOADER_SHOW: 'APP_USER_LOADER_SHOW',
   APP_USER_LODER_HIDE: 'APP_USER_LODER_HIDE',
   FETCH_APP_USERS: 'FETCH_APP_USERS',
   FETCH_APPUSER_CLEAR: 'FETCH_APPUSER_CLEAR',
   INVITE_APPLICATION_USER: 'INVITE_APPLICATION_USER',
   IS_APP_USER_DELETE_SUCCESS: 'IS_APP_USER_DELETE_SUCCESS',
   IS_APP_USER_EDIT_SUCCESS: 'IS_APP_USER_EDIT_SUCCESS',
   IS_APP_USER_SUCCESS: 'IS_APP_USER_SUCCESS',
   ISCHECK_EMAIL_EXISTS: 'ISCHECK_EMAIL_EXISTS',
   ISCHECK_EMAIL_NOT_EXISTS: 'ISCHECK_EMAIL_NOT_EXISTS',
   PROVERSION_TO_CLIENT: 'PROVERSION_TO_CLIENT',
   UPDATE_MY_ACCOUNT_USERNAME: 'UPDATE_MY_ACCOUNT_USERNAME'
};

export const userManageReducerConstant = {
   FETCH_COUNTRY_LIST: 'FETCH_COUNTRY_LIST',
   FETCH_ROLES_LIST: 'FETCH_ROLES_LIST',
   FETCH_STATE_LIST: 'FETCH_STATE_LIST',
   FETCH_USER_BY_WORKFLOW_ID: 'FETCH_USER_BY_WORKFLOW_ID',
   FETCH_USER_CLEAR: 'FETCH_USER_CLEAR',
   FETCH_USER_DETALS: 'FETCH_USER_DETALS',
   IS_USER_DELETE_FAILURE: 'IS_USER_DELETE_FAILURE',
   IS_USER_DELETE_SUCCESS: 'IS_USER_DELETE_SUCCESS',
   IS_USER_EDIT_FAILURE: 'IS_USER_EDIT_FAILURE',
   IS_USER_EDIT_SUCCESS: 'IS_USER_EDIT_SUCCESS',
   IS_USER_FAILURE: 'IS_USER_FAILURE',
   IS_USER_SUCCESS: 'IS_USER_SUCCESS',
   IS_USER_LOGGING_OUT: 'IS_USER_LOGGING_OUT',
   ISCHECK_EMAIL_EXISTS: 'ISCHECK_EMAIL_EXISTS',
   ISCHECK_EMAIL_NOT_EXISTS: 'ISCHECK_EMAIL_NOT_EXISTS',
   LOADER_SHOW: 'LOADER_SHOW',
   LODER_HIDE: 'LODER_HIDE',
   UPDATE_USER_MANAGEMENT_USERNAME: 'UPDATE_USER_MANAGEMENT_USERNAME',
   UPDATE_IMPERIAL_PREFERENCE: 'UPDATE_IMPERIAL_PREFERENCE',
   USER_ADD_CLEAR: 'USER_ADD_CLEAR',
   USER_DELETE_CLEAR: 'USER_DELETE_CLEAR',
   USER_EDIT_CLEAR: 'USER_EDIT_CLEAR',
   USERTABLE_LOADER: 'USERTABLE_LOADER',
   WORKFLOW_LODER_SHOW: 'WORKFLOW_LODER_SHOW',
   IS_MY_PROFILE_VISIBLE: 'IS_MY_PROFILE_VISIBLE'
};

export const clientManageReducerConstant = {
   CLIENT_ADD_CLEAR: 'CLIENT_ADD_CLEAR',
   CLIENT_DELETE_CLEAR: 'CLIENT_DELETE_CLEAR',
   CLIENT_EDIT_CLEAR: 'CLIENT_EDIT_CLEAR',
   FETCH_CLIENT_BY_ID: 'FETCH_CLIENT_BY_ID',
   FETCH_CLIENTS: 'FETCH_CLIENTS',
   FETCH_CLIENTS_CLEAR: 'FETCH_CLIENTS_CLEAR',
   FETCH_COUNTRY_LIST: 'FETCH_COUNTRY_LIST',
   FETCH_STATE_LIST: 'FETCH_STATE_LIST',
   IS_CLIENT_DELETE_SUCCESS: 'IS_CLIENT_DELETE_SUCCESS',
   IS_CLIENT_EDIT_SUCCESS: 'IS_CLIENT_EDIT_SUCCESS',
   IS_CLIENT_SUCCESS: 'IS_CLIENT_SUCCESS',
   LOADER: 'LOADER'
};

export const roleManageReducerConstant = {
   FETCH_MENU: 'FETCH_MENU',
   FETCH_ROLE_BY_ID: 'FETCH_ROLE_BY_ID',
   FETCH_ROLES: 'FETCH_ROLES',
   IS_ROLE_DELETE_SUCCESS: 'IS_ROLE_DELETE_SUCCESS',
   IS_ROLE_EDIT_SUCCESS: 'IS_ROLE_EDIT_SUCCESS',
   IS_ROLE_SUCCESS: 'IS_ROLE_SUCCESS',
   RELOAD_ROLE: 'RELOAD_ROLE',
   ROLE_ADD_CLEAR: 'ROLE_ADD_CLEAR',
   ROLE_DELETE_CLEAR: 'ROLE_DELETE_CLEAR',
   ROLE_EDIT_CLEAR: 'ROLE_EDIT_CLEAR',
   ROLES_LOADER: 'ROLES_LOADER'
};

export const dataImportReducerConstant = {
   ALL_UNITS: 'ALL_UNITS',
   CHUNKS_ADD_CLEAR: 'CHUNKS_ADD_CLEAR',
   CLEAR_DATA_SAVE_COMPLETED: 'CLEAR_DATA_SAVE_COMPLETED',
   CLEAR_ERROR_VALIDATION_FIELDMAPPING: 'CLEAR_ERROR_VALIDATION_FIELDMAPPING',
   CLEAR_EXCEL_LAZY_LOAD: 'CLEAR_EXCEL_LAZY_LOAD',
   CLEAR_MATCHING_DETAIL_UPDATE: 'CLEAR_MATCHING_DETAIL_UPDATE',
   CLEAR_QCDASHBOARD_DATA: 'CLEAR_QCDASHBOARD_DATA',
   CLEAR_VENDOR_OPAREA: 'CLEAR_VENDOR_OPAREA',
   CLEAR_DATASUMMARY_VENDOR_OP_AREA: 'CLEAR_DATASUMMARY_VENDOR_OP_AREA',
   CLEAR_EXCEL_DATA: 'CLEAR_EXCEL_DATA',
   CLOSE_VALIDATION_DIALOG: 'CLOSE_VALIDATION_DIALOG',
   DATA_IMPORT_LOADER: 'DATA_IMPORT_LOADER',
   DATA_SAVE_LOADER: 'DATA_SAVE_LOADER',
   DELIMITER_DATA_ADDED: 'DELIMITER_DATA_ADDED',
   DISABLE_QC_BUTTONS: 'DISABLE_QC_BUTTONS',
   ERROR_VALIDATION_FIELDMAPPING: 'ERROR_VALIDATION_FIELDMAPPING',
   FETCH_DELIMITER_LIST: 'FETCH_DELIMITER_LIST',
   FETCH_EXCEL_DATA_FAILURE: 'FETCH_EXCEL_DATA_FAILURE',
   FETCH_EXCEL_DATA_LAZY_LOAD_SUCCESS: 'FETCH_EXCEL_DATA_LAZY_LOAD_SUCCESS',
   FETCH_EXCEL_DATA_SUCCESS: 'FETCH_EXCEL_DATA_SUCCESS',
   FETCH_FILE_GUID: 'FETCH_FILE_GUID',
   FETCH_FEATURE_TYPE: 'FETCH_FEATURE_TYPE',
   FETCH_ILI_SUMMARY_DASHBOARD: 'FETCH_ILI_SUMMARY_DASHBOARD',
   FETCH_UPDATED_MATCHING_DETAILS: 'FETCH_UPDATED_MATCHING_DETAILS',
   IDR_WARNING_DATA: 'IDR_WARNING_DATA',
   ILI_SUMMARY_LIST: 'ILI_SUMMARY_LIST',
   ILI_SUMMARY_SCREEN_DATA: 'ILI_SUMMARY_SCREEN_DATA',
   IS_CHUNKS_SUCCESS: 'IS_CHUNKS_SUCCESS',
   IS_REDUNDANT_PROCESS_REQUIRED: 'IS_REDUNDANT_PROCESS_REQUIRED',
   IS_TEMPLATE_SUCCESS: 'IS_TEMPLATE_SUCCESS',
   IS_VENDOR_OPERATIONAL_CLEAR: 'IS_VENDOR_OPERATIONAL_CLEAR',
   IS_VENDOR_OPERATIONAL_SUCCESS: 'IS_VENDOR_OPERATIONAL_SUCCESS',
   MASTER_LIST: 'MASTER_LIST',
   OPERATIONAL_AREA_LIST: 'OPERATIONAL_AREA_LIST',
   QUANTITY_LIST: 'QUANTITY_LIST',
   REMOVE_OPERATIONAL_LOADER: 'REMOVE_OPERATIONAL_LOADER',
   REMOVE_SUMMARY_LOADER: 'REMOVE_SUMMARY_LOADER',
   REMOVE_VENDOR_LOADER: 'REMOVE_VENDOR_LOADER',
   REMOVE_VERSIONING_LOADER: 'REMOVE_VERSIONING_LOADER',
   RESET_QC_DATA: 'RESET_QC_DATA',
   REVERT_SUCCESS_REDIRECT: 'REVERT_SUCCESS_REDIRECT',
   SAVE_QC_RESPONSE: 'SAVE_QC_RESPONSE',
   SAVE_DATA_ENTRY_VALUE: 'SAVE_DATA_ENTRY_VALUE',
   SELECTED_ILISUMMARY_SHEETDATA: 'SELECTED_ILISUMMARY_SHEETDATA',
   SELECTED_OPERATIONAL_AREA_UPDATE: 'SELECTED_OPERATIONAL_AREA_UPDATE',
   SELECTED_SHEET_ARRAY: 'SELECTED_SHEET_ARRAY',
   SELECTED_VENDOR_UPDATE: 'SELECTED_VENDOR_UPDATE',
   SET_FILE_NAME: 'SET_FILE_NAME',
   SET_OPERATIONAL_LOADER: 'SET_OPERATIONAL_LOADER',
   SET_PREVIOUS_VERSION_QC_DATA: 'SET_PREVIOUS_VERSION_QC_DATA',
   SET_SUMMARY_LOADER: 'SET_SUMMARY_LOADER',
   SET_VENDOR_LOADER: 'SET_VENDOR_LOADER',
   SET_VERSIONING_LOADER: 'SET_VERSIONING_LOADER',
   SET_QC_DASHBOARD_DATA: 'SET_QC_DASHBOARD_DATA',
   SHOW_WARNING_DIALOG: 'SHOW_WARNING_DIALOG',
   TEMPLATE_ADD_CLEAR: 'TEMPLATE_ADD_CLEAR',
   UNIT_LIST: 'UNIT_LIST',
   UPDATE_EXCEL_TEMPLATE: 'UPDATE_EXCEL_TEMPLATE',
   UPDATE_TOOL_TYPE: 'UPDATE_TOOL_TYPE',
   UPDATE_FIELD_MAPPING: 'UPDATE_FIELD_MAPPING',
   UPDATE_RESULT_CONTENT: 'UPDATE_RESULT_CONTENT',
   VENDORS_LIST: 'VENDORS_LIST',
   VERSION_LIST: 'VERSION_LIST',
   VALIDATING_ROWS: 'VALIDATING_ROWS'
};

export const snackBarReducerConstantSeverity = {
   SUCCESS: 'success',
   ERROR: 'error',
   WARNING: 'warning'
};

export const dashboardReducerConstant = {
   GET_DASHBOARD_EMBED: 'GET_DASHBOARD_EMBED',
   GET_ANALYSIS_DASHBOARD_EMBED: 'GET_ANALYSIS_DASHBOARD_EMBED'
};

export const searchReducerConstant = {
   GET_DEFAULT_FIELD_DATA: 'GET_DEFAULT_FIELD_DATA',
   GET_SEARCH_RESULTS: 'GET_SEARCH_RESULTS',
   GET_ANALYSIS_DASHBOARD_DATA: 'GET_ANALYSIS_DASHBOARD_DATA',
   GET_ANALYSIS_DASHBOARD_OTHER_DATA: 'GET_ANALYSIS_DASHBOARD_OTHER_DATA',
   GET_ANALYSIS_BACKGROUND_DATA: 'GET_ANALYSIS_BACKGROUND_DATA',
   SEARCH_LOADER_STATE_UPDATE: 'SEARCH_LOADER_STATE_UPDATE',
   RESET_DEFAULT_STATE: 'RESET_DEFAULT_STATE',
   UPDATE_ANALYSIS_DASHBOARD_DATA: 'UPDATE_ANALYSIS_DASHBOARD_DATA',
   CLEAR_ANALYSIS_DATA: 'CLEAR_ANALYSIS_DATA',
   RETAIN_SEARCH_DATA: 'RETAIN_SEARCH_DATA',
   SEARCH_DATA_RETAIN: 'SEARCH_DATA_RETAIN'
};

export const overviewDashboardReducerConstant = {
   GET_DEFAULT_FIELD_DATA: 'GET_DEFAULT_FIELD_DATA',
   GET_SEARCH_RESULTS: 'GET_SEARCH_RESULTS',
   GET_ANALYSIS_DASHBOARD_DATA: 'GET_ANALYSIS_DASHBOARD_DATA',
   GET_ANALYSIS_DASHBOARD_OTHER_DATA: 'GET_ANALYSIS_DASHBOARD_OTHER_DATA',
   SEARCH_LOADER_STATE_UPDATE: 'SEARCH_LOADER_STATE_UPDATE',
   RESET_DEFAULT_STATE: 'RESET_DEFAULT_STATE',
   UPDATE_ANALYSIS_DASHBOARD_DATA: 'UPDATE_ANALYSIS_DASHBOARD_DATA',
   CLEAR_ANALYSIS_DATA: 'CLEAR_ANALYSIS_DATA',
   RETAIN_SEARCH_DATA: 'RETAIN_SEARCH_DATA',
};
export const lookupManagementConstant = {
   GET_ALL_SOURCES: 'GET_ALL_SOURCES',
   LOOKUP_TABLE_LOADER: 'LOOKUP_TABLE_LOADER',
   LOOKUP_ADD_CLEAR: 'LOOKUP_ADD_CLEAR',
   FETCH_LOOKUP_DETAILS: 'FETCH_LOOKUP_DETAILS',
   IS_LOOKUP_ADDED: 'IS_LOOKUP_ADDED',
   IS_LOOKUP_UPDATED: 'IS_LOOKUP_UPDATED',
   IS_LOOKUP_DELETED: 'IS_LOOKUP_DELETED',
   GET_ALL_ANOMALY_CLASSIFICATION: 'GET_ALL_ANOMALY_CLASSIFICATION',
   GET_ALL_ANOMALY_CATEGORY: 'GET_ALL_ANOMALY_CATEGORY',
   GET_ALL_INTERNAL_EXTERNAL: 'GET_ALL_INTERNAL_EXTERNAL',
   GLOBAL_LOOKUP_LOADER: 'GLOBAL_LOOKUP_LOADER'
};
