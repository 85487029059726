/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
export const arrayConstants = {
   nonEmptyArray: 0,
   currentRelationSplice: 1,
   excelUnitAbbreviation: 1,
   initialOrder: 0,
   preferenceOrder: 1,
   tokenData: 1
};
