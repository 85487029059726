/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import { searchReducerConstant } from '../../reducerConstant';
import _ from 'lodash';
import { isLengthGreaterThanZero } from '../../../components/shared/helper';
import { searchDisplayText } from '../../../searchconstants';

const initialState = {
   operationalAreas: [],
   vendors: [],
   projectNames: [],
   fromLocations: [],
   toLocations: [],
   toolTypes: [],
   searchLoader: false,
   searchLoaderMessage: null,
   searchResults: [],
   isSearchDataRecieved: false,
   dashboardInspectionGuid: null,
   dashboardInspectionId: null,
   dashboardVersionId: null,
   dashboardProjectName: null,
   dashboardFromLocation: null,
   dashboardToLocation: null,
   dashboardVendor: null,
   dashboardToolType: null,
   dashboardRunStartDate: null,
   dashboardRunEndDate: null,
   dashboardImportDate: null,
   dashboardStatus: null,
   analysisDashboardData: null,
   dashboardMaximumDataLimit: null,
   dashboardMinimumDataLimit: null,
   dashboardDataLimit: null,
   dashboardDataTotalCount: null,
   analysisDashboardBack: false,
   retainSearchData: {},
   allOverviewData: null,
   searchExport: null,
};

const searchReducer = (state = initialState, action = {}) => {
   const { type, value } = action;
   switch (type) {
      case searchReducerConstant.GET_DEFAULT_FIELD_DATA:
         return {
            ...state,
            operationalAreas: value.operationAreaDto ?? state.operationalAreas,
            vendors: value.vendorDto ?? state.vendors,
            projectNames: value.projectNames ?? state.projectNames,
            fromLocations: isLengthGreaterThanZero(state.fromLocations) ? state.fromLocations : value.fromLocation,
            toLocations: isLengthGreaterThanZero(state.toLocations) ? state.toLocations : value.toLocation,
            toolTypes: isLengthGreaterThanZero(state.toolTypes) ? state.toolTypes : value.toolTypeDto,
            searchLoader: false
         };
      case searchReducerConstant.SEARCH_LOADER_STATE_UPDATE:
         return {
            ...state,
            searchLoader: value.isLoaderVisible,
            searchLoaderMessage: value.loaderMessage
         };
      case searchReducerConstant.GET_SEARCH_RESULTS:
         return {
            ...state,
            searchResults: value,
            isSearchDataRecieved: true,
            searchLoader: false,
            searchLoaderMessage: null
         };
      case searchReducerConstant.RESET_DEFAULT_STATE:
         return {
            ...state,
            searchResults: [],
            isSearchDataRecieved: false,
            searchLoader: false,
            searchLoaderMessage: null
         };
      case searchReducerConstant.UPDATE_ANALYSIS_DASHBOARD_DATA:
         return {
            ...state,
            dashboardInspectionGuid: value.inspectionGuid,
            dashboardInspectionId: value.inspectionId,
            dashboardVersionId: value.versionId,
            dashboardProjectName: value.projectName,
            dashboardFromLocation: value.fromLocation,
            dashboardToLocation: value.toLocation,
            dashboardVendor: value.vendor,
            dashboardToolType: value.toolType,
            dashboardRunStartDate: value.runStartDate,
            dashboardRunEndDate: value.runEndDate,
            dashboardImportDate: value.importDate,
            dashboardStatus: value.status,
         };
      case searchReducerConstant.GET_ANALYSIS_DASHBOARD_DATA:
         return {
            ...state,
            analysisDashboardData: value.analysisDashboardData,
            dashboardMaximumDataLimit: value.maxDistance,
            dashboardMinimumDataLimit: (_.head(value.analysisDashboardData)).distance,
            dashboardDataTotalCount: value.totalCount,
            dashboardDataLimit: value.midPointDistance,
            allData: value
         };
      case searchReducerConstant.GET_ANALYSIS_DASHBOARD_OTHER_DATA:
         return {
            ...state,
            analysisDashboardData: value.analysisDashboardData,
            allData: value
         };
      case searchReducerConstant.CLEAR_ANALYSIS_DATA:
         return {
            ...state,
            analysisDashboardData: null,
            dashboardMaximumDataLimit: null,
            dashboardMinimumDataLimit: null,
            dashboardDataTotalCount: null,
            allData: null
         };
      case searchReducerConstant.RETAIN_SEARCH_DATA:
         return {
            ...state,
            analysisDashboardBack: value
         };
      case searchReducerConstant.SEARCH_DATA_RETAIN:
         return {
            ...state,
            retainSearchData: value
         };
      case searchReducerConstant.GET_ANALYSIS_BACKGROUND_DATA:
         const { tabName, data } = value;
         if (tabName === searchDisplayText.METAL_LOSS_TAB_VALUE) {
            return {
               ...state,
               metalLossTabData: data
            };
         }
         return {
            ...state,
            deformationTabData: data
         };
      default:
         return state;
   }
};

export default searchReducer;
