/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import axios from 'axios';
import { displayText, sessionStorageKey, stringConstant } from '../../constant';
import { checkCookieIsPresent, decryptData } from '../../components/shared/helper';

const apiUrl = process.env.REACT_APP_API_URL ?? stringConstant.EMPTY_STRING;
export default {
   // Get all
   getAllData: url => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .get(`${apiUrl}${displayText.API}/${url}`, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
            }
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   postData: (url, data) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .post(`${apiUrl}${displayText.API}/${url}`, data, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
            }
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   //Revoke Token API call
   revokePostData: (url, data) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios.defaults.withCredentials = true;
      axios.post(`${apiUrl}${displayText.API}/${url}`, {}, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
            Authorization: `${displayText.BEARER} ${tokenValue}`,
            ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
         }
      })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   loginUser: (url, data) => new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true;
      axios.post(`${apiUrl}${displayText.API}/${url}`, data, { crossDomain: true }, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
         }
      })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            reject(err);
         });
   }),

   // Refresh Token
   cookiePostData: url => new Promise((resolve) => {
      axios.defaults.withCredentials = true;
      axios.post(`${apiUrl}${displayText.API}/${url}`, {}, { crossDomain: true }, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
         }
      })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   editData: (url, data) => {
      const { tokenValue } = checkCookieIsPresent('token');
      return new Promise((resolve, reject) => {
         axios
            .put(`${apiUrl}${displayText.API}/${url}`, data, {
               headers: {
                  'Content-Type': displayText.APPLICATION_JSON,
                  Authorization: `${displayText.BEARER} ${tokenValue}`,
                  ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
               }
            })
            .then(result => {
               resolve(result);
            })
            .catch(err => {
               resolve(err.message);
            });
      });
   },

   deleteCall: (url, data) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .delete(`${apiUrl}${displayText.API}/${url}`, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
            },
            data
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   getExcelPreview: (url, data) => new Promise((resolve, reject) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios.post(`${apiUrl}${displayText.API}/${url}`, data, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
            Authorization: `${displayText.BEARER} ${tokenValue}`,
            ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
         }
      })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            reject(err);
         });
   }),

   passwordChangePostCall: (url, data) => new Promise((resolve) => {
      axios.post(`${apiUrl}${displayText.API}/${url}`, data, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
         },
      })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   deleteFileCall: url => new Promise((resolve, reject) => {
      const { tokenValue } = checkCookieIsPresent('token');
      fetch(`${apiUrl}${displayText.API}/${url}`, {
         headers: {
            Authorization: `${displayText.BEARER} ${tokenValue}`,
            ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
         },
         credentials: 'include',
         method: 'POST',
         mode: 'same-origin',
         keepalive: true
      })
         .then(result => {
            resolve(result);
         })
         .catch(e => {
            reject(e);
         });
   }),

   qcDashboardCall: (url, data) => new Promise(async (resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      await fetch(`${apiUrl}${displayText.API}/${url}`, {
         headers: {
            'Content-Type': displayText.APPLICATION_JSON,
            Authorization: `${displayText.BEARER} ${tokenValue}`,
            ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
         },
         body: JSON.stringify(data),
         credentials: 'include',
         method: 'POST'
      })
      .then(result => {
         resolve(result.json());
      })
      .catch(e => {
         console.log(e);
      });
   }),
   
   postDataAsyncWithCancelToken: (url, data, newCancelToken, isCancel) => new Promise(async (resolve, reject) => {
      const { tokenValue } = checkCookieIsPresent('token');
      try {
         const result = await axios.post(`${apiUrl}${displayText.API}/${url}`, data, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`
            },
            cancelToken: newCancelToken,
         });
         resolve(result);
      } catch (error) {
         if (isCancel) {
            reject('user has cancelled the request.');
         }
         resolve(error.message);
      }
   }),

   getAllLookupData: (url, clientId) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .get(`${apiUrl}${displayText.API}/${url}`, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`,
               ...(clientId) && { CurrentClientGuid: clientId }
            }
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   postLookupData: (url, data, clientId) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .post(`${apiUrl}${displayText.API}/${url}`, data, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`,
               ...(clientId) && { CurrentClientGuid: clientId }
            }
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   }),

   editLookupData: (url, data, clientId) => {
      const { tokenValue } = checkCookieIsPresent('token');
      return new Promise((resolve) => {
         axios
            .put(`${apiUrl}${displayText.API}/${url}`, data, {
               headers: {
                  'Content-Type': displayText.APPLICATION_JSON,
                  Authorization: `${displayText.BEARER} ${tokenValue}`,
                  ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`,
                  ...(clientId) && { CurrentClientGuid: clientId }
               },
            })
            .then(result => {
               resolve(result);
            })
            .catch(err => {
               resolve(err.message);
            });
      });
   },

   deleteLookupCall: (url, data, clientId) => new Promise((resolve) => {
      const { tokenValue } = checkCookieIsPresent('token');
      axios
         .delete(`${apiUrl}${displayText.API}/${url}`, {
            headers: {
               'Content-Type': displayText.APPLICATION_JSON,
               Authorization: `${displayText.BEARER} ${tokenValue}`,
               ClientsGuid: `${JSON.parse(decryptData(sessionStorageKey.USER_CURRENT_CLIENT_DETAILS))?.clientsGuid}`,
               ...(clientId) && { CurrentClientGuid: clientId }
            },
            data
         })
         .then(result => {
            resolve(result);
         })
         .catch(err => {
            resolve(err.message);
         });
   })
};
