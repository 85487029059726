/*
 *  Copyright © 2021 Cenozon Inc. All Rights Reserved.
 */
import React from 'react';

export default function Loading() {
   return (
      <div className='pt-3 text-center'>
         <div className='sk-spinner sk-spinner-pulse'></div>
      </div>
   );
}
